// Настройка пользовательских стилей для Tab

// Настройка пользовательских стилей для TabPanel
.MuiTabPanel-root {
  // Ваши пользовательские стили для каждого TabPanel
  // Например, добавьте отступы или другие стили по вашему выбору
  color: #000;
  padding: 24px 24px 24px 0 !important;

  @media screen and (max-width: 768px){
    padding: 24px 0 !important;
  }
}

.i_tabs-header{
  .MuiTabs-flexContainer{
    gap: 20px;
    @media screen and (max-width: 560px){
      flex-direction: column;
    }
  }
  &.i_tabs-header-edit{
    .i_tabs-header-item{
      padding: 0 40px 0 20px;
    }
  }
  .i_tabs-header-item{
    position: relative;
    padding: 0 20px;
    border: 1px solid #ebebeb;
    border-radius: 30px;
    text-transform: none;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    transition: all .4s ease;
    min-height: 40px;
    gap: 10px;
    overflow: visible;

    display: flex;
    flex-direction: row;

    &:hover{
      border: 1px solid #fe2942;
    }

    @media screen and (max-width: 768px){
      &:hover{
        border: 1px solid #ebebeb;
      }
    }

    @media screen and (max-width: 560px){
      width: 100%;
      max-width: 100%;
    }


    &.Mui-selected {
      // Стили для выбранного Tab
      color: #000;
      background-color: #f8f8f8;
      border: 1px solid #f8f8f8;
    }

    .i_tabs-header-item-edit{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 20px;
        height: 20px;
        fill: #7b7b7b;
        transition: all .4s ease;
      }
      &:hover{
        svg{
          fill: #fe2942;
        }
      }
    }

  }
}

.i_tabs-content-left{
  padding: 0 24px 0 0 !important;
  @media screen and (max-width: 768px){
    padding: 0 !important;
  }
}

.i_tabs-header-left{
  margin-top: 24px;
}

.i_tap-panel{
  position: relative;
}
