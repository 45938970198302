// MAIN PAGE

.i_main-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.i_main-page-skeleton {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.i_text-bold {
  font-weight: 600;
}

.i_main-widgets {
  display: flex;
  gap: 30px;
  height: 100%;
}

.i_main-widget {
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  padding: 30px 15px;
  width: 100%;
  height: 100%;
  transition: all .4s ease;

  .i_main-widget-header {
    display: flex;
    justify-content: center;
    height: 40%;
  }

  .i_main-widget-bottom {
    margin-top: 40px;
    height: 55%;

    .i_main-widget-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      gap: 15px;

      .i_main-widgets-svg {
        div {
          height: 24px;
        }

        svg {
          width: 24px;
          height: 24px;
          fill: #fe2942;
        }
      }
    }

    .i_main-widget-items {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 5px;
      height: 90%;
      overflow-y: auto;

      .i_main-widget-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-radius: 50px;
        cursor: pointer;
        transition: all .4s ease;
        font-size: 0.8rem;
        height: 35px;

        .i_main-widget-item-count,
        .i_main-widget-item-active {
          padding: 5px 15px;
          border-radius: 50px;
          transition: all .4s ease;
        }

        .i_main-widget-item-active.active {
          color: #01c716;
        }

        .i_main-widget-item-title {
          transition: all .4s ease;
        }

        &:hover {
          background-color: #f8f8f8;

          .i_main-widget-item-title {
            color: #fe2942;
          }

          .i_main-widget-item-count {
            background-color: #fff;
            color: #fe2942;
          }

          .i_main-widget-item-active {
            background-color: #fff;
          }
        }
      }
    }
  }
}

#mainpagecatalogwidget,
#MainPageDealerWidget {
  height: 100%;
  max-height: 275px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.i_dealer-main-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;


  .i_dealer-main-stats{
    height: 100%;
    width: 49%;
  }

  .i_dealer-main-start{
    height: 100%;
    width: 49%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;
    padding: 30px 10px;

    .i_dealer-main-start-title{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      gap: 15px;

      .i_dealer-main-start-svg {
        div {
          height: 24px;
        }

        svg {
          width: 24px;
          height: 24px;
          path{
            fill: #fe2942;
            stroke: #fe2942;
          }
        }
      }
    }

    .i_dealer-start-accordion{
      span,p{
        font-family: 'Montserrat', sans-serif;
        margin: 0;
      }

      svg{
        path{
          fill: #fe2942;
        }
      }
      
      blockquote{
        background-color: #f8f8f8;
        border-left: 2px solid #fe2942;
        padding: 10px 20px;
        margin: 10px 0;
      }

      ol{
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .i_dealer-start-accordion-head{
        font-weight: 500;
      }
    }
  }

  a {
    text-decoration: none;
    color: #0184db;
  }
}




//MEDIA QUERIES

@media screen and (max-width: 768px) {
  .i_main-page {
    padding-bottom: 20px;
    height: max-content;
    .i_main-widgets {
      flex-direction: column;
      gap: 20px;

      .i_main-widget {
        width: 100%;
        .i_main-widget-bottom{
          height: 300px;
          .i_main-widget-items{
            height: 85%;
          }
        }
      }
    }

    .i_main-widget-items{
      #scrollBox{
        max-height: 270px;
      }
    }

    .i_dealer-main-page{
      flex-direction: column;
      gap: 20px;
      .i_dealer-main-stats,
      .i_dealer-main-start{
        width: 100%;
        padding: 20px 10px;
      }

      .i_dealer-main-stats{
        padding: 20px 0;
      }

      .i_main-widget-bottom{
        height: 100%;
        max-height: 300px;
      }
    }
  }
}

