.basketItems{
  display: flex;
  flex-direction: column;

  .basketItem{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 0.875rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 15px;
    cursor: pointer;
    transition: all .4s ease;

    @media screen and (min-width: 1024px){
      &:hover{
        background-color: #f8f8f8;
      }
    }

    .basketItemName{
      width: 40%;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;

      .basketItemNameSubtitle{
        width: max-content;
        font-size: .6875rem;
        color: #808080;
        display: flex;
        flex-direction: row;
        gap: 7px;
      }
    }

    .basketItemPrice{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      width: 12%;
      .basketItemPriceTitle{
        font-size: .625rem;
        color: #808080;
      }
      .basketItemPriceValue{
        font-size: 1rem;
      }
    }

    .basketItemDelete{
      width: 26px;
      height: 26px;
    }
    @media screen and (max-width: 900px){
      padding: 10px 0;
      gap: 10px;
      .basketItemName{
        width: 80%;
      }

      .basketItemPrice{
        width: 35%;
      }


    }

    @media screen and (max-width: 620px){
      .basketItemName{
        width: 70%;
      }
    }

    @media screen and (max-width: 460px){
      gap: 5px;
      .basketItemName{
        order: 1;
        width: 100%;
      }

      .quantity{
        order: 2;
        width: 45%;
      }

      .basketItemDelete{
        order: 3;
        width: 46%;
        display: flex;
        justify-content: flex-end;
      }

      .basketPricePurchase{
        order: 4;
        width: max-content;
      }

      .basketPriceRetail{
        order: 5;
        width: max-content;
      }
    }
  }


  .basketItemImg{
    img{
      width: 50px;
    }
  }
}