.i_dealer-page{
  .i_dealer-items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    .i_dealer-item{
      width: 24%;

      @media screen and (max-width: 1410px){
        width: 32%;
      }

      @media screen and (max-width: 1024px){
        width: 48%;
      }

      @media screen and (max-width: 560px){
        width: 100%;
      }

      position: relative;
      padding: 20px 15px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      transition: all .4s ease;
      font-size: 0.875rem;
      .i_dealer-item-title{
        font-size: 1.125rem;
      }

      .i_dealer-item-active{
        font-size: 0.750rem;
        span{
          &.green{
            color: #01c716;
          }
          &.red{
            color: #fe2942;
          }
          &.gray{
            color: #ccc;
          }
        }
      }

      .i_dealer-switch{
        position: absolute;
        top: 14px;
        right: 10px;
        width: max-content;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .i_dealer-item-actives-stats{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 15px;
      }

      .i_dealer-item-title{
        margin-bottom: 15px;
        margin-top: 10px;
      }

      a{
        color: #0184db;
        text-decoration: none;
        font-weight: 500;
      }

      .i_dealer-item-request{
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .i_dealer-page-skeleton{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}