.headerItem {
  padding: 10px 12px;
  border-radius: 80px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;

  @starting-style{
    width: 100%;
  }

  &.active{

  }

  .itemValue{
    text-decoration: none;
    color: #000;
    &:hover{
      span{
        color: #fe2942;
      }
    }
  }

  .dotted {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #aaa;
  }

  &.companyName {
    font-size: 0.875rem;
    position: relative;
    background: transparent;
    transition: all .4s ease;

    .headerItemCompany{
      top: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 36px;
      padding: 6px 12px;
      border-radius: 80px;
      background: #f8f8f8;
      z-index: 2;
      transition: height .4s ease, min-width .4s ease;

      .companyHead {
        position: relative;
        display: flex;

        .companyHeadLeft {
          display: flex;
          gap: 10px;
          padding-top: 3px;

          .companyNameTitle {
            text-wrap: nowrap;
          }

          .companyRoleTitle {
            display: flex;
            gap: 10px;
            flex-direction: row;
            align-items: center;
            color: #aaa;

            .circle {
              display: block;
              width: 5px;
              height: 5px;
              border-radius: 100px;
              background-color: #aaa;
            }
          }

          @media screen and (max-width: 1024px) {
            flex-direction: column;
            gap: 0;
            .circle {
              display: none !important;
            }
          }
        }

        .companyClose {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          border: 1px solid #e7e7e7;
          opacity: 0;
          visibility: hidden;
          transition: all .2s ease;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .companyMore {
      position: absolute;
      top: 50px;
      opacity: 0;
      visibility: hidden;
      width: 87%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .companyMoreItem {
        display: flex;
        gap: 10px;
        padding-left: 10px;
        align-items: center;
        transition: all .4s ease;

        span {
          transition: all .4s ease;
        }

        &:hover {
          span {
            color: #fe2942;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .companyRoleTitle {
        display: none !important;
        margin-left: 3px;
      }
      .companyNameTitle{
        margin-top: 3px;
        margin-left: 3px;
      }
    }

    &.active {
      .headerItemCompany {
        border-radius: 15px;
        box-shadow: 0 2px 16px 2px rgb(24 24 24 / 14%);
      }

      .companyMore {
        opacity: 1;
        visibility: visible;
        height: max-content;
      }

      .companyClose {
        opacity: 1 !important;
        visibility: visible !important;
      }

      @media screen and (max-width: 1024px) {
        .companyRoleTitle {
          display: block !important;
        }
      }
    }
  }
}