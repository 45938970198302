.i_catalog-page.markups {
  width: 100%;
  height: 100%;

  .i_catalog-page-empty,
  .i_catalog-product-empty{
    margin: 20px 0;
    position: relative;
    width: 400px;
    display: flex;
    gap: 15px;
    font-size: 0.815rem;
    flex-direction: column;
    padding: 20px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;
    justify-content: center;

    @media screen and (max-width: 1024px){
      width: 100%;
    }

    a {
      text-decoration: none;
      color: #0184db;
    }

    img {
      width: 25px;
      height: 25px;
    }

    .i_catalog-page-empty-title {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  .i_catalog-page-skeleton {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 14px;
  }

  .i_catalog-page-in-skeleton {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .react-loading-skeleton:nth-child(1) {
      width: 20%;
    }

    .react-loading-skeleton:nth-child(2) {
      width: 79%;
    }
  }

  .i_catalog-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      flex-wrap: nowrap;
      height: auto;
    }

    .i_catalog-product {
      width: 100%;
      @media (max-width: 1301px){
        margin-right: 60px;
      }
      overflow-x: auto;
      height: calc(100% - 44px);
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}