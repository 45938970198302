.i_orders {
  .i_orders-tabs {
    background-color: #f8f8f8;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 0.815rem;

    .i_orders-tabs-items {
      display: flex;

      .i_orders-tabs-item {
        &.id { width: 10%; }
        &.client { width: 10%; }
        &.date { width: 10%; }
        &.status { width: 10%; }
        &.products { width: 35%; }
        &.distributors { width: 15%; }
        &.summary { width: 10%; }
      }
    }
  }

  .i_orders-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;

    .i_orders-item {
      font-size: 0.815rem;
      border-radius: 10px;
      border: 2px solid #f1f1f1;
      max-height: 39px;
      overflow: hidden;
      transition: max-height 0.4s ease;
      cursor: pointer;

      &.hidden { max-height: 39px; }
      &.show {
        max-height: 1000px;
        .i_orders-item-header {
          background-color: #f8f8f8;
        }
      }

      .i_orders-item-header {
        display: flex;
        padding: 10px 15px;
        transition: all .4s ease;
        .i_orders-item-id { width: 10%; }
        .i_orders-item-client { width: 10%; }
        .i_orders-item-date { width: 10%; }
        .i_orders-item-status { width: 10%; }
        .i_orders-item-products-count { width: 35%; }
        .i_orders-item-distributors-count { width: 15%; }
        .i_orders-item-summary { width: 10%; }
      }

      .i_orders-item-body {
        transition: opacity 0.4s ease, visibility 0.4s ease;
        opacity: 0;
        visibility: hidden;
        padding: 10px 15px;
        display: flex;
        .i_order-item-detail{
          width: calc(40% - 2px);
          display:flex;
          flex-direction: column;
          gap: 15px;
        }
        .i_orders-item-products{
          width: 60%;
          display: flex;
          flex-direction: column;
          gap: 15px;
          .i_order-item-products-header{
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #eee;
            .i_order-item-products-header-name { width: 35%; }
            .i_order-item-products-header-count { width: 5%; text-align: center;}
            .i_order-item-products-header-price { width: 15%; padding-left: 25px;}
            .i_order-item-products-header-sum { width: 17%; padding-left: 35px;}
            .i_order-item-products-header-distributor { width: 25%; }
          }
          .i_order-item-products-body{
            display: flex;
            flex-direction: column;
            gap: 15px;
            .i_order-item-product{
              width: 100%;
              display: flex;
              padding-bottom: 15px;
              border-bottom: 1px solid #eee;
              &:last-child{
                border-bottom: none;
              }
              .i_order-item-product-name { width: 35%; }
              .i_order-item-product-count { width: 5%; text-align: center; }
              .i_order-item-product-price { width: 15%; padding-left: 25px; }
              .i_order-item-product-sum { width: 17%; padding-left: 35px; }
              .i_order-item-product-distributor {width: 25%; }
            }
          }
        }
      }

      &.show .i_orders-item-body {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
