.modalItem{
  text-align: start;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 15px 15px;
  background-color: #fff;
  transition: all 1s ease;
  &.modalItemUnread{
    background-color: #fffbf7;
  }
  .modalItemDate,
  .modalItemMessage{
    font-size: 0.875rem;
  }
}