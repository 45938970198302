.multitoolsItem {
  width: 18px;
  height: 18px;
  cursor: pointer;

  &.disabled {
    opacity: .67;
    pointer-events: none;

    svg {
      path {
        fill: #b7b7b7;
        transition: all .4s ease;
      }
    }
  }

  &.attachItem {
    margin-bottom: 2px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      width: 18px;
      height: 18px;
    }
  }


  div {
    width: 18px;
    height: 18px;

    svg {
      width: 26px;
      height: 26px;

      path {
        fill: #fe2942;
        transition: all .4s ease;
      }
    }
  }
}

.catalogToolsItem{
  cursor: pointer;
  height: 26px;
  width: 26px;
  transition: all .4s ease;
  div{
    width: 26px;
    height: 26px;
  }
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    svg{
      path{
        fill: #7b7b7b;
      }
    }
  }
}