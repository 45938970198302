.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }

  .modalContent{
    width: 100%;
  }

  .exportBlock{
    width: 100%;
    position: relative;
    padding: 10px 0;
  }

  .modalButton{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .files{
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 15px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 0.85rem;

    .filesTitle{
      transform: translateY(-60%);
      position: absolute;
      transition: all 0.2s ease;
      color: #757575;
      font-size: 0.75rem;
      top: 0;
      left: 18px;
      padding: 0 10px;
      background: #fff;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .filesList{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .file{
      display : flex;
      align-items: center;
      justify-content: space-between;
      transition: all .4s ease;
      cursor: pointer;
      padding: 10px;
      border-radius: 15px;

      &:hover{
        background-color: #f8f8f8;
      }

      @media screen and (max-width: 570px){
        gap: 10px;
        align-items: start;

        font-size: 0.875rem;

        .fileData{
          width: 100%;
          font-size: 0.75rem;
          flex-direction: row;
        }
        .fileLeft{
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          margin-top: 4px;
        }
      }
    }

    .fileLeft{
      width: 64%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .fileRight{
      width: 32%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .fileData{
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 40%;
      font-size: 0.625rem;
      text-align: center;
      color: #808080;
    }

    .fileStatus{
      width: 30%;
    }

    .fileID{
      width: 10%;
    }

    .fileBtn{
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .4s ease;
    }
  }

  .disabled{
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}