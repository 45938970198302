.order{
  padding: 30px 0;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: max-height .4s ease;
  border-top: 1px solid #ebebeb;
  overflow: hidden;

  .orderItems{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  &.close{
    padding-bottom: 0;
    max-height: 0;
  }

  .orderItemName,
  .orderItemPhone,
  .orderItemEmail,
  .orderItemAddress{
    flex-grow: 1;
    width: 48%;
    transition: unset;
  }

  .orderItemComment{
    transition: unset;
  }

  .orderButton{
    display: flex;
    width: 100%;
    justify-content: end;
  }

  .orderTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }

  .orderType{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-bottom: 10px;

    .orderTypeItems{
      display: flex;
      gap: 20px;
    }
  }

}