.i_matching{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .i_matching-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .i_matching-left{
    width: 48%;
  }
  .i_matching-right{
    width: 48%;
    .i_matching-catalog-empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .i_matching-catalog-add{
      padding: 20px 15px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      min-height: 220px;
      width: 100%;
      transition: all 0.4s ease;
      color: #000;
      cursor: pointer;
      gap: 20px;
      svg{
        width: 70px;
        height: 70px;
        fill: #9a9a9a;
        transition: all .4s ease;
      }

      &:hover{
        svg{
          width: 70px;
          height: 70px;
          fill: #fe2942;
        }
      }

    }
  }
  .i_matching-message{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    position: relative;
    display: flex;
    gap: 15px;
    font-size: 0.815rem;
    flex-direction: column;
    padding: 20px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;
    justify-content: center;
    img{
      width: 25px;
      height: 25px;
    }
    .i_matching-message-title{
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
  .i_matching-catalog{
    border: 1px solid #ccc;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px 10px;
    font-size: 1.125rem;
    border-radius: 15px;
    position: relative;
    .i_matching-tools{
      border-bottom: 1px solid #ccc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .i_matching-tools-left{
        width: 50%;
        .i_search-highlight input{
          border-bottom: 0;
          margin-left: 0;
        }
      }
    }

    .i_search-highlight{
      input{
        padding-left: 10px;
        border-radius: 15px;
      }
      &::before{
        position: absolute;
        left: 0;
        content: "";
        width: 0;
        height: 44px;
        background-color: #f8f8f8;
        border-radius: 15px 15px 15px 0px;
        z-index: -1;
        transition: all .2s ease;
      }
      .i_search-highlight-btn{
        position: relative;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease;
      }
      &.active{
        &::before{
          width: 51%;
        }
        .i_search-highlight-btn{
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .i_matching-catalog-view{
      font-size: 0.815rem;
      padding: 10px 0 10px 10px;
      transition: all .4s ease;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      width: max-content;

      &:hover{
        color: #fe2942;
      }
      .i_matching-catalog-view-item{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .i_catalog-show-product{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;
      transition: all .4s ease;
      z-index: 3;
      border-radius: 15px;
      padding: 25px;
      font-size: 0.875rem;
      .i_catalog-show-product-view{
        position: absolute;
        right: 80px;
        top: 20px;
        background-color: #fff;
        cursor: pointer;
        transition: all .4s ease;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        &:hover{
          color: #fe2942;
        }
      }
      .i_catalog-show-products{
        overflow-y: auto;
        height: 95%;
        padding-right: 20px;
        padding-top: 5px;
        display: block;
        cursor: pointer;
        .i_catalog-show-product-box{
          position: relative;
          margin: 2px 0;
        }
        .i_catalog-show-product-status{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);

          &.active{ background-color: #01c716; }
          &.inactive{ background-color: #cdcdcd; }

        }
        .i_catalog-show-product-checked,
        .i_select-input-box{
          padding: 7px 10px 7px 30px;
          border-radius: 15px;
          min-height: 34px;
          transition: all .4s ease;
          .i-select-contain{
            margin-bottom: 0;
          }
          &:hover{
            background-color: #f3f3f3;
          }
        }
      }
      .i_catalog-show-product-title{
        font-size: 1.125rem;
        padding-bottom: 15px;
      }
      .i_catalog-show-product-close{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        cursor: pointer;
        position: absolute;
        z-index: 3;
        top: 20px;
        right: 20px;
        img{
          width: 17px;
          height: 17px;
        }
      }
      .i_catalog-show-product-checked{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        //padding: 10px 10px 10px 30px !important;
        &:first-child{
          margin-top: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }
        &.close{
          max-height: 34px;
        }
        &.open{
          max-height: 100px;
          background-color: #f3f3f3;
        }
        &.right-row{
          max-height: none;
          padding: 7px 10px 8px 30px;
          .i_catalog-show-product-delete{
            min-width: 18px;
          }
          .i_catalog-show-product-head{
            gap: 20px;
            margin: 3px 0;
          }
        }
        .i_catalog-show-product-head{
          display: flex;
          align-items: center;
          gap: 7px;
          font-size: 0.8rem;
          position: relative;
          margin-bottom: 3px;
          line-height: 1.5;
        }
        .i_catalog-show-product-body{
          padding-left: 25px;
          font-size: 0.815rem;
          .i_catalog-show-product-item{
            padding: 7px 10px 7px 12px;
            background: white;
            border-radius: 15px;
            display: flex;
            width: max-content;
            margin: 5px 0;
            gap: 10px;
            align-items: center;
            transition: all 0.4s ease;
            &:hover{
                cursor: pointer;
                color: #fe2942;
            }
          }
        }
        .i_catalog-show-product-chain{
          height: 18px;
          width: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
              width: 11px;
              height: 11px;
          }
        }
        .i_catalog-show-product-delete{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          border-radius: 100%;
          border: 1px solid #e7e7e7;
          cursor: pointer;
          position: relative;
          margin-left: auto;
          img{
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .i_catalog-category-items{
      display: flex;
      flex-direction: column;
      height: 73vh;
      overflow-y: auto;
      padding: 0 15px 0 15px;
      margin: 10px 0 0 0;

      .i_catalog-category-item-level-line{
        position: absolute;
        left: 1px;
        width: 1px;
        background-color: #d1d1d1;
        height: calc(100% - 36px);
        top: 15px;
      }

      .i_catalog-category-item{
        position: relative;
        font-size: 0.815rem;
        &:not(&.i_catalog-category-item-level-0){
         margin: 5px 0;
        }
        &::before{
          content: "";
          width: 0;
          height: 100%;
          position: absolute;
          top: 0;
          border-radius: 15px;
          left: 0;
          transition: all .2s ease;
          border: 0 solid #d7d7d7;
        }
        &.show-match.last-child{
          margin-bottom: 5px;
          &::before{
            width: 100%;
            border: 1px solid #d7d7d7;
          }
          .i_match-item{
            border: 1px solid #d7d7d7;
          }
          &.i_catalog-category-item-level-0{
            &::before{
              width: 103%;
              left: -15px;
            }
          }
        }

        &.active.last-child{
          &::before{
            width: 100%;
            background-color: #f3f3f3;
            border: 1px solid #f3f3f3;
          }
          .i_match-item{
            border: 1px solid #f3f3f3;
          }
          .i_catalog-title-circle{
            background-color: #fff;
          }
          &.i_catalog-category-item-level-0{
            &::before{
              width: 103%;
              left: -15px;
            }
          }
        }
      }

      .i_catalog-category-item-title{
        padding: 10px 0;
        position: relative;
        transition: all .4s ease;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        .i_title-chain{
          height: 10px;
          width: 10px;
          margin-bottom: 3px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .i_catalog-title-title{
          transition: all .4s ease;
        }
        &:hover{
          color: #fe2942;
          cursor: pointer;
        }
        .i_catalog-view-product{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          display: flex;
          align-items: center;
          gap: 10px;
          transition: all .4s ease;
          img{
            width: 20px;
            height: 20px;
          }
          &:hover{
            cursor: pointer;
            color: #fe2942;
          }
        }
        .i_catalog-title-circle{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          border-radius: 100%;
          left: -20px;
          background-color: #ebebeb;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          z-index: 1;
          img{
            width: 6px;
            height: 6px;
            opacity: .6;
          }
          &:not(.last-child){
            &.active{
              &::after{
                content: "";
                width: 6px;
                height: 6px;
                background-color: #767676;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translate(0%, -50%);
              }
            }
          }
        }
      }

      .i_catalog-category-item-match{
        position: relative;
        padding-top: 0px;
        padding-bottom: 15px;
        display: block;
        &.hide{
          display: none;
        }
        .i_match-items{
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          flex-direction: row;
          .i_match-item{
            padding: 5px 10px 7px 12px;
            background: white;
            border-radius: 15px;
            display: flex;
            gap: 10px;
            align-items: center;
            transition: all .4s ease;
            .i_match-view-product{
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                width: 20px;
                height: 20px;
              }
            }
            &:hover{
              cursor: pointer;
              color: #fe2942;
            }
            .i_match-item-delete{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 18px;
              height: 18px;
              border-radius: 100%;
              border: 1px solid #e7e7e7;
              cursor: pointer;
              position: relative;
              top: 1px;
              right: -2px;
              img{
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }

      .i_catalog-category-item-level-0{
        position: relative;
        margin-bottom: 5px;
        .i_catalog-category-item-level-1{
          display: none;
        }
        &.active{
          .i_catalog-category-item-level-1{
            display: block;
          }
        }
      }

      @for $i from 1 through 10 {
        .i_catalog-category-item-level-#{$i} {
          position: relative;

          .i_catalog-category-item-level-#{$i + 1} {
            display: none;
          }

          &.active {

            .i_catalog-category-item-level-#{$i + 1} {
              display: block;
            }
          }
        }
      }

    }
  }
}

//MEDIA QUERIES

@media screen and (max-width: 768px){
  .i_matching{
    flex-direction: column;
    gap: 35px;

    .i_matching-left{
      width: 100%;
    }

    .i_matching-right{
      width: 100%;
    }
  }
}
