//LOADER
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  z-index: 9999;

  opacity: 1;
  visibility: visible;
  transition: none;

  &.hide {
    /* Добавляем анимацию только при скрытии */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  .loaderRipples {
    position: absolute;
    z-index: -1;
    width: 250px;
    height: 250px;
  }

  .loaderLogo {
    position: relative;
    z-index: 1;
    background-color: white;
    opacity: 0.6;
    border-radius: 100%;
  }

  img {
    width: 150px;
    height: 150px;
  }
}