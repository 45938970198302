//I_MULTIHELP
.multiHelp {
  position: fixed;
  background-color: #fff;
  z-index: 999;
  pointer-events: none;
  border-radius: 15px;
  padding: 5px 10px;
  color: black;
  font-size: 12px;
  border: 1px solid #ccc;
  max-width: 400px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;

  &.hide{
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    display: none;
    &.showInMobile{
      display: block !important;
    }
  }
}