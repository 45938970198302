.SettingsPriceList{
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .SettingsPriceListItem{
    width: 100%;
    text-align: start;

    &:last-child{
      min-width: 125px;
      width: 125px;
    }
  }
}