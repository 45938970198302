.loader{
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 10;
  height: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  top: 0;

  svg{
    width: 50px;
    height: 50px;
  }
}