.headerItem{
  padding: 10px 12px;
  border-radius: 80px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;
  .dotted{
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #aaa;
  }
  &.multitools{
    background-color: #fff;
    padding: 0;
  }
  &.profile{
    font-size: 0.875rem;
    position: relative;
    min-width: 130px;
    background: transparent;
    transition: all .4s ease;
    .headerItemProfile{
      top: 0;
      right: 0;
      position: absolute;
      width: 100%;
      padding: 6px 12px;
      border-radius: 80px;
      background: #f8f8f8;
      z-index: 2;
      transition: height .4s ease, min-width .4s ease;
    }
    .profileHead{
      position: relative;
      display: flex;
      .profileLeft{
        display: flex;
        align-items: center;
        gap: 7px;
        text-wrap: nowrap;
      }
      .profileClose{
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 1px solid #e7e7e7;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease;
        img{
          width: 16px;
          height: 16px;
        }
      }
    }
    img{
      width: 24px;
    }

    .profileMore{
      position: absolute;
      top: 50px;
      opacity: 0;
      visibility: hidden;
      width: 87%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .profileMoreItem{
        display: flex;
        gap: 10px;
        padding-left: 10px;
        align-items: center;
        text-wrap: nowrap;
        transition: all .4s ease;
        &:hover{
          color: #fe2942;
        }
      }
    }

    &.active{
      .headerItemProfile{
        box-shadow: 0 2px 16px 2px rgb(24 24 24 / 14%);
      }
      .profileMore{
        opacity: 1;
        visibility: visible;
      }
      .profileClose{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media screen and (max-width: 1300px){

}