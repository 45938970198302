.iSelectContain *,
.iSelectContain *::before,
.iSelectContain *::after {
  box-sizing: content-box !important;
}

.iSelectContain input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.iSelectContain span {
  line-height: 1.5;
  font-size: 0.8rem;
  height: 100%;
  display: flex;
  font-family: inherit;
  align-items: center;
}

.iSelectContain {
  display: flex;
  gap: 10px;
  position: relative;
  cursor: pointer;
  width: 100%;
  text-align: left;
  height: 20px;
}

.iSelectContain input[type="checkbox"] ~ .iSelectInput {
  position: relative;
  height: 16px;
  width: 16px;
  background: rgba(249, 249, 249, 0);
  transition: all 0.4s ease;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.iSelectContain input[type="checkbox"] ~ .iSelectInput::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 0.2rem;
  height: 0.5rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: all 0.4s ease;
  transform: rotate(45deg);
}

.iSelectContain input[type="checkbox"]:disabled ~ .iSelectInput::after {
  border-color: #ffffff;
}

.iSelectContain input:checked ~ .iSelectInput::after {
  display: block;
}

.iSelectContain:hover input[type="checkbox"]:not([disabled]) ~ .iSelectInput,
.iSelectContain input[type="checkbox"]:focus ~ .iSelectInput {
  background: #fff;
  border-color: #777;
}

.iSelectContain input[type="checkbox"]:checked ~ .iSelectInput {
  background: #5757B7;
  border-color: #5757B7;
}

.iSelectContain input[type="checkbox"]:disabled ~ .iSelectInput {
  opacity: 0.5;
  cursor: not-allowed;
}

.iSelectContain:hover input[type="checkbox"]:not([disabled]):checked ~ .iSelectInput,
.iSelectContain input[type="checkbox"]:checked:focus ~ .iSelectInput {
  background: #5757B7;
  border-color: #5757B7;
}

.disabled{
  pointer-events: none;
  opacity: 0.5;
}

.inputHelpIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  svg {
    width: 20px;
    height: 20px;
    transition: all 0.4s ease;
  }

  &:hover {
    svg {
      fill: #fe2942;
    }
  }
}
