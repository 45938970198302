.settingsCurrencyListItem{
  position: relative;
  display: flex;
}

.settingsCurrencyList{
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
}

.settingsCurrencyListTitle,
.settingsCurrencyListDefaultTitle{
  transform: translateY(-60%);
  position: absolute;
  transition: all 0.2s ease;
  color: #757575;
  font-size: 0.75rem;
  top: 0;
  padding: 0 10px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.settingsCurrencyListTitle{
  left: 18px;
}

.settingsCurrencyListDefaultTitle{
  right: 18px;
}


