.i_select-role {
  height: 100vh;
  width: 100%;

  .container {
    height: 100%;

    @media screen and (max-width: 860px){
      padding: 30px;
    }
  }

  .i_header {
    .i_header-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 860px){
        gap: 20px;
        flex-wrap: wrap;
      }

      .i_header-item-right {
        display: flex;
        gap: 40px;
        align-items: center;

        .selected-language {
          gap: 10px;
        }
      }

      .i_header-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.logo {
          width: max-content;

          img {
            width: 185px;
            height: 45px;
            object-fit: contain;
          }
        }

        &.account {
          display: flex;
          flex-direction: row;
          font-size: 0.875rem;
          gap: 12px;
          height: 40px;
          padding: 0 15px;
          border-radius: 80px;
          background-color: #f6f6f6;

          img {
            width: 24px;
          }
        }

        .i_country-viewer {
          position: relative;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          background-color: #f6f6f6;
          border-radius: 20px;
          overflow: hidden;
          font-size: 0.875rem;
          height: 40px;
          padding: 0 15px 0 100px;

          .i_country-viewer-svg {
            position: absolute;

            img {
              width: 100%;
              height: auto;
            }
          }

          &.uz {
            .i_country-viewer-svg {
              left: 0;
              top: -30px;
              width: 100px;
              transform: rotateZ(310deg);
            }
          }

          &.kz {
            .i_country-viewer-svg {
              left: 0;
              top: -20px;
              width: 90px;
              transform: rotateZ(315deg);
            }
          }

          &.kg {
            .i_country-viewer-svg {
              left: -19px;
              top: -12px;
              width: 110px;
              transform: rotateZ(310deg);
            }
          }
        }
      }
    }
  }

  .i_select-content {
    display: flex;
    height: 90vh;
    flex-direction: column;
    gap: 45px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 860px){
      height: 100%;
      justify-content: unset;
      margin-top: 40px;
    }

    .i_select-text {
      min-width: 770px;
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;

      @media screen and (max-width: 860px){
        width: 100%;
        min-width: unset;
      }

      .i_select-title {
        font-size: 1.3rem;
      }

      .i_select-subtitle {
        font-size: 1rem;
        display: block;
      }
    }

    .i_select-roles {
      width: 70%;
      min-width: 770px;

      @media screen and (max-width: 860px){
        width: 100%;
        min-width: unset;
        padding-bottom: 50px;
      }

      .i_roles-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 680px) {
          flex-direction: column;
          gap: 40px;
        }

        .i_roles-item {
          padding: 20px 30px;
          background-color: #f6f6f6;
          display: flex;
          justify-content: space-between;
          border-radius: 20px;
          flex-direction: column;
          width: 45%;
          gap: 30px;

          @media screen and (max-width: 680px) {
              width: 100%;
          }

          .i_role-text {
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-size: 0.875rem;
            padding-left: 20px;

            .i_role-text-item {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: #5757b7;
                left: -18px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

          .i_role-btn {
            color: #fff;
            background: linear-gradient(90deg, #6c6cc7, #5757b7);
            padding: 0 20px;
            border-radius: 80px;
            cursor: pointer;
            width: 100%;
            transition: all .4s ease;
            opacity: 1;
            text-align: center;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }

    .i_select-roles-ps {
      min-width: 770px;
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;
    }
  }
}
