.i_catalog-page-empty,
.i_catalog-product-empty,
.i_catalog-category-empty {
  margin: 20px 0;
  position: relative;
  width: 400px;
  display: flex;
  gap: 15px;
  font-size: 0.815rem;
  flex-direction: column;
  padding: 20px 15px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #0184db;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .i_catalog-page-empty-title {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

.i_catalog-category-empty {
  border: 2px solid #f1f1f1;
  width: 100%;
}

.i_catalog-page {
  width: 100%;
  height: 100%;

  .i_catalog-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 14px;
    transition: all .4s ease;

    &.distributors-catalogs {
      .i_catalog-item {
        .i_catalog-item-name {
          margin-top: 20px;
        }
      }
    }

    .i_catalog-item {
      width: calc(100% / 5 - 12px);
      max-width: 440px;
      position: relative;
      padding: 20px 15px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      min-height: 340px;
      gap: 40px;
      transition: all .4s ease;
      color: #000;

      a {
        text-decoration: none;
        color: #000;
      }

      &:hover {
        cursor: pointer;

        &.add-item {
          svg {
            width: 70px;
            height: 70px;
            fill: #fe2942;
          }
        }

        &:hover {
          .i_catalog-item-name {
            a, span {
              color: #fe2942;
            }
          }
        }
      }

      &.add-item {
        cursor: pointer;

        svg {
          width: 70px;
          height: 70px;
          fill: #9a9a9a;
          transition: all .4s ease;
        }
      }
    }

    .i_catalog-item-active{
      position: absolute;
      font-size: .75rem;
      background-color: #fe2942;
      color: white;
      left: 10px;
      top: 10px;
      padding: 2px 10px;
      border-radius: 30px;

      &.show {
        background-color: #01c716;
      }

      &.close {
        background-color: #069fff;
      }
    }

    .i_catalog-item-name {
      a, span {
        font-size: 1.3rem;
        transition: all .4s ease;
        text-decoration: none;
        color: #000;
      }
    }

    .i_catalog-item-date {
      font-size: 0.815rem;
      color: #b7b7b7;
    }

    .i_catalog-item-detail{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .i_catalog-item-code,
    .i_catalog-item-currency{
      color: #b7b7b7;
      position: relative;

      .i_clipboard {
        right: -45px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .i_catalog-item-stats {
      display: flex;
      font-size: 0.815rem;
      justify-content: center;
      gap: 15px;
      width: 100%;

      .i_catalog-item-category-active,
      .i_catalog-item-product-active {
        padding: 5px 15px;
        border-radius: 50px;
        background: #f8f8f8;
      }
    }

    .i_catalog-item-btn {
      display: flex;
      gap: 50px;

      .i_catalog-item-btn-markup {
        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
          transition: all .4s ease;

          path {
            stroke: #7b7b7b;
            stroke-width: 1.5px;
            transition: all .4s ease;
          }

          //
          //path{
          //  stroke-width: 1;
          //  transition: all .4s ease;
          //
          //  &:nth-child(1){
          //    stroke-width: 0;
          //  }
          //}

          &:hover {
            path {
              stroke: #fe2942;
              transition: all .4s ease;
            }
          }
        }
      }

      .i_catalog-item-btn-edit {
        svg {
          width: 27px;
          height: 27px;
          fill: #7b7b7b;
          cursor: pointer;
          transition: all .4s ease;

          &:hover {
            fill: #fe2942;
          }
        }
      }

      .i_catalog-item-btn-trash {
        svg {
          fill: #7b7b7b;
          cursor: pointer;
          transition: all .4s ease;

          &:hover {
            fill: #fe2942;
          }
        }
      }
    }
  }

  .i_catalog-page-skeleton {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 14px;
  }

  .i_catalog-page-in-skeleton {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .react-loading-skeleton:nth-child(1) {
      width: 20%;
    }

    .react-loading-skeleton:nth-child(2) {
      width: 79%;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      .react-loading-skeleton:nth-child(1) {
        width: 0 !important;
      }
      .react-loading-skeleton:nth-child(2) {
        width: 100% !important;
      }
    }
  }

  .i_catalog-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      height: auto;
      max-height: 100%;
      flex-wrap: nowrap;
    }

    .i_category-list-mobile {
      display: none;
      padding: 10px 20px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      &:hover {
        cursor: pointer;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: #fe2942;
          width: 14px;
          height: 14px;
        }
      }

      @media screen and (max-width: 1024px) {
        display: flex;
      }
    }

    .i_catalog-category {
      border: 2px solid #f1f1f1;
      width: 20%;
      padding: 8px 10px;
      font-size: 1.125rem;
      border-radius: 15px;
      height: 100%;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      .i_catalog-tools {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border-bottom: 2px solid #f1f1f1;
        padding-bottom: 6px;

        .i_catalogtools {
          height: unset;
          border: 0;
          gap: 10px;
          padding: 0;

          .i_catalogtools-item {
            width: 24px;
            height: 24px;
            cursor: pointer;

            &.disabled {
              opacity: 1;

              svg {
                path {
                  fill: #b7b7b7;
                  transition: all .4s ease;
                }
              }
            }

            div {
              width: 24px;
              height: 24px;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .i_catalog-tools-right {
          display: flex;
          gap: 10px;
        }

        .i_tools-item {
          cursor: pointer;

          div {
            width: 24px;
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          &.disabled {
            opacity: .5;
            pointer-events: none;
          }
        }
      }

      .i_catalog-category-items {
        display: flex;
        flex-direction: column;
        height: 90%;
        overflow-y: auto;
        padding: 0 15px 15px;
        margin-top: 10px;

        &::-webkit-scrollbar {
          width: 2px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
          background-color: rgba(118, 118, 118, 0.3);
        }

        &:hover {
          &::-webkit-scrollbar-thumb {
            display: block;
            background-color: #767676FF;
            transition: all .4s ease;
          }
        }


        .i_catalog-category-item-level-line {
          position: absolute;
          left: 1px;
          width: 1px;
          background-color: #d1d1d1;
          height: calc(100% - 32px);
          top: 15px;
        }

        .i_catalog-category-item {
          position: relative;
          font-size: 0.815rem;

        }

        .i_catalog-category-item-title {
          padding: 10px 0;
          position: relative;
          transition: all .4s ease;

          &:hover {
            color: #fe2942;
            cursor: pointer;
          }

          &.selected {
            color: #fe2942;
          }

          .i_catalog-title-circle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            border-radius: 100%;
            left: -20px;
            background-color: #ebebeb;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            z-index: 1;

            img {
              width: 6px;
              height: 6px;
              opacity: .6;
            }

            &:not(.last-child) {
              &.active {
                &::after {
                  content: "";
                  width: 6px;
                  height: 6px;
                  background-color: #767676;
                  border-radius: 100%;
                  position: absolute;
                  top: 50%;
                  left: 4px;
                  transform: translate(0%, -50%);
                }
              }
            }
          }
        }

        .i_catalog-category-item-level-0 {
          padding-left: 15px;
          position: relative;

          .i_catalog-category-item-level-1 {
            display: none;
          }

          &.active {
            .i_catalog-category-item-level-1 {
              display: block;
            }
          }
        }

        @for $i from 1 through 10 {
          .i_catalog-category-item-level-#{$i} {
            position: relative;

            .i_catalog-category-item-level-#{$i + 1} {
              display: none;
            }

            &.active {
              .i_catalog-category-item-level-#{$i + 1} {
                display: block;
              }
            }
          }
        }

      }
    }


    .i_catalog-product {
      width: 79%;
      overflow-x: auto;

      @media screen and (max-width: 1024px) {
        width: 100%;
        //margin-bottom: 15px;
      }

      .i_catalog-product-head {
        display: flex;
        align-items: center;
        font-size: 0.815rem;
        background-color: #f8f8f8;
        padding: 10px 15px;
        border-radius: 10px;

        @media screen and (max-width: 1024px) {
          min-width: 100%;
          margin-bottom: 5px;
          padding: 3px;
          border-radius: 25px;
        }

        &.source-product {
          padding: 10px 0;

          .i_catalog-product-head-item {
            &.active {
              width: 8%;
            }
          }
        }

        .i_catalog-product-head-item {
          width: 20%;

          &.products {
            display: none;
          }

          &.name {
            width: 40%;
          }

          &.active {
            width: 13%;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1024px) {
              display: none;
            }
          }

          &.id {
            width: 10%;
            padding: 0 10px;
          }

          &.article,
          &.quantity {
            width: 13%;
          }

          &.price,
          &.rrp {
            width: 15%;
          }

          &.checkbox {
            width: 40px;
            min-width: 40px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .i-select-contain {
              display: flex;
              position: relative;
              padding-left: 0;
              margin-bottom: 0;
              justify-content: center;
            }
          }

          @media screen and (max-width: 1024px) {
            display: none;
            &.products {
              display: block;
              margin-left: 20px;
            }
          }
        }
      }

      .i_catalog-product-items {
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: 83vh;

        @media screen and (max-width: 1024px) {
          min-width: 100%;
          max-height: unset;

          &.source-product {
            height: 100%;
          }
          height: 100%;
        }

        //gap: 30px;
        .i_catalog-product-item {
          display: flex;
          padding: 10px 0;
          align-items: center;
          font-size: 0.815rem;
          border-bottom: 1px solid #ebebeb;
          cursor: pointer;
          position: relative;
          transition: all .4s ease;

          &.source-product {
            .i_catalog-product-item-checkbox {
              width: 40px;
              min-width: 40px;
              padding: 0;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              @media screen and (max-width: 1024px) {
                grid-column: span 1;
                grid-row: span 1;
              }

              .i-select-contain {
                display: flex;
                position: relative;
                padding-left: 0;
                margin-bottom: 0;
                justify-content: center;
              }
            }

            .i_catalog-product-item-active {
              width: 8%;
              @media screen and (max-width: 1024px) {
                width: 100%;
              }
            }
          }

          &:hover {
            background-color: #f8f8f8;
          }

          @media screen and (max-width: 1024px) {
            display: grid;
            grid-template-columns: repeat(14, 1fr); // три колонки
            grid-template-rows: auto; // высота строки будет автоматической
            gap: 15px 10px;
            &:hover {
              background-color: unset;
            }
          }
        }

        .i_catalog-product-more {
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: #fff;
            background: linear-gradient(90deg, #6c6cc7, #5757b7);
            padding: 10px 20px;
            border-radius: 80px;
            cursor: pointer;
            width: 15%;
            transition: all 0.4s ease;
            opacity: 1;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1024px) {
              width: 50%;
            }
          }
        }

        .i_catalog-product-item > * {
          width: 20%;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }

        .i_catalog-product-item-name {
          width: 40%;
          padding-left: 0;
        }

        .i_catalog-product-item-active {
          width: 13%;
          display: flex;
          justify-content: center;

          .item-circle {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            border-radius: 50%;
            background-color: #cdcdcd;

            &.active {
              background-color: #01c716;
            }
          }
        }

        .i_catalog-product-item-id {
          width: 10%;
        }

        .i_catalog-product-item-article {
          width: 13%;
        }

        .i_catalog-product-item-price,
        .i_catalog-product-item-rrp {
          width: 15%;
        }

        .i_catalog-product-item-quantity {
          width: 13%;
        }

        .i_catalog-product-item-title {
          display: none;
        }

        @media screen and (max-width: 1024px) {
          .i_catalog-product-item-active {
            width: 100%;
            grid-column: span 1; // растягивает элемент на две колонки
            grid-row: span 1; // растягивает элемент на две строки
            justify-content: start;
            padding: 0;
          }
          .i_catalog-product-item-name,
          .i_catalog-product-item-price,
          .i_catalog-product-item-quantity {
            display: flex;
            gap: 5px;
          }

          .i_catalog-product-item-quantity {
            width: 100%;
            grid-column: span 5; // растягивает элемент на две колонки
            padding: 0;
            margin-left: calc(17% + 10px);
          }

          .i_catalog-product-item-price {
            width: 100%;
            grid-column: span 9; // растягивает элемент на две колонки
          }

          .i_catalog-product-item-name {
            grid-column: span 12; // растягивает элемент на две колонки
            grid-row: span 1; // растягивает элемент на две строки
          }

          .i_catalog-product-item-title {
            display: block;
          }

          .i_catalog-product-item-name {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 3840px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: calc(100% / 8 - 12px);
      }
    }
  }
}

@media screen and (min-width: 2560px) and (max-width: 3840px){
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: calc(100% / 7 - 12px);
      }
    }
  }
}

@media screen and (max-width: 2560px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: calc(100% / 6 - 12px);
      }
    }
  }
}

@media screen and (max-width: 1920px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: calc(100% / 5 - 12px);
      }
    }
  }
}

//MEDIA QUERIES
@media screen and (max-width: 1520px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: 24%;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: 24% !important;
    }
  }
}

@media screen and (max-width: 1340px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: 23%;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: 23% !important;
    }
  }
}

@media screen and (max-width: 1280px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: calc(33% - 7px);
      }
    }

    .i_catalog-product {
      .i_catalog-product-head {
        min-width: 1000px;
      }

      .i_catalog-product-items {
        min-width: 1000px;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: calc(33% - 7px) !important;
    }
  }
}

@media (max-width: 1024px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        max-width: unset;
        width: calc(50% - 7px);
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: calc(50% - 7px) !important;
    }
  }
}

@media (max-width: 560px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: 100%;
        gap: 25px;
        min-height: 260px;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: 100% !important;
    }
  }
}


