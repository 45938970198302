.iCatalogCategoryItem {
  position: relative;
  font-size: 0.815rem;
  text-align: start;
}

.iCatalogCategoryItemTitle {
  padding: 10px 0;
  position: relative;
  transition: all 0.4s ease;

  &:hover {
    color: #fe2942;
    cursor: pointer;
  }

  &.selected {
    color: #fe2942;
  }

  .iCatalogTitleCircle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 100%;
    left: -20px;
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 1;

    img {
      width: 6px;
      height: 6px;
      opacity: 0.6;
    }

    &:not(.lastChild) {
      &.active {
        &::after {
          content: "";
          width: 6px;
          height: 6px;
          background-color: #767676;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translate(0%, -50%);
        }
      }
    }
  }
}

.iCatalogCategoryItemLevel0 {
  padding-left: 15px;
  position: relative;

  .iCatalogCategoryItemLevel1 {
    display: none;
  }

  &.active {
    .iCatalogCategoryItemLevel1 {
      display: block;
    }
  }
}

@for $i from 1 through 10 {
  .iCatalogCategoryItemLevel#{$i} {
    position: relative;

    .iCatalogCategoryItemLevel#{$i + 1} {
      display: none;
    }

    &.active {
      .iCatalogCategoryItemLevel#{$i + 1} {
        display: block;
      }
    }
  }
}
