.i_catalog-category-items {
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow-y: auto;
  padding: 15px;

  @media screen and (max-width: 1024px){
    padding: 0 10px 0 0;
  }
  //background-color: #f8f8f8;

  &::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(118, 118, 118, 0.3);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #767676FF;
      transition: all .4s ease;
    }
  }

  .i_catalog-category-item {
    position: relative;
    font-size: 0.815rem;
  }

  .i_catalog-category-item-select {
    position: relative;
    font-size: .625rem;
    color: #fff;
    border-radius: 25px;
    padding: 3px 7px;
    background-color: #6c6cc7;
    &.selected{
      background-color: #01c716;
    }
  }

  .i_catalog-category-item-title {
    padding: 10px 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    transition: all .4s ease;

    &:hover {
      color: #fe2942;
      cursor: pointer;
    }

    &.selected {
      color: #fe2942;
    }

    .i_catalog-title-circle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      border-radius: 100%;
      left: -20px;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      z-index: 1;

      img {
        width: 6px;
        height: 6px;
        opacity: .6;
      }

      &:not(.last-child) {
        &.active {
          &::after {
            content: "";
            width: 6px;
            height: 6px;
            background-color: #767676;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translate(0%, -50%);
          }
        }
      }
    }
  }

  .i_catalog-category-item-level-0 {
    padding-left: 15px;
    position: relative;

    .i_catalog-category-item-level-1 {
      display: none;
    }

    &.active {
      .i_catalog-category-item-level-1 {
        display: block;
      }
    }
  }

  @for $i from 1 through 10 {
    .i_catalog-category-item-level-#{$i} {
      position: relative;

      .i_catalog-category-item-level-#{$i + 1} {
        display: none;
      }

      &.active {
        .i_catalog-category-item-level-#{$i + 1} {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 1024px){
    height: calc(90% - 30px);
  }
}