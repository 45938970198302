.toolsItem {
  cursor: pointer;

  div {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}