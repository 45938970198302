.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  text-align: center;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }
  .modalItems{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .modalError{
    color: red;
    font-size: 0.875rem;
    margin-top: 10px;
  }

  .modalBtn{
    margin-top: 20px;
  }
}


