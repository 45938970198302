.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
  text-align: center;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }
  .modalContent{
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    align-items: center;
  }

  .i_add-category-items {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .i_add-category-modal-btn {
    margin: 0 auto;
  }

  .i_add-category-modal-error {
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    color: #fe2942;
  }
}

.modalItem{
  position: relative;
  width: 100%;
}

.modalItemDelete{
  position: absolute;
  right: -5px;
  top: -5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: #f8f8f8;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  svg{
    width: 100%;
    height: 100%;
  }
}

.checkboxBlock{
  width: 100%;
  display: flex;
  align-items: start;
}



