.productBlock {
  width: 100%;
  overflow-x: auto;
  display: flex;
  height: calc(100% - 52px);
  flex-direction: column;
}

.productBlockLoader {
  transition: all 0.4s ease;
  position: absolute;
  width: 77.7%;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    width: 97%;
  }

  .catalogPageInSkeleton {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  &.hideLoader {
    opacity: 1;
    visibility: visible;

    animation: hideLoaderAnimation .5s forwards;
  }
}

@keyframes hideLoaderAnimation {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
