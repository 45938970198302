//I SWITCH BUTTON

.switchBtn{
  width: max-content;
  display: flex;
  align-items: center;
  gap: 20px;

  &.small{
    font-size: 0.75rem;
    gap: 10px;
    .switch{
      width: 36px;
      height: 18px;
    }

    .slider::before{
      height: 12px;
      width: 12px;
    }
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(1, 199, 22);
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
