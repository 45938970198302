.productItems {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 81vh;
  min-height: 81vh;
  height: 90%;
  width: max-content;

  @media screen and (max-width: 1024px) {
    min-width: 100%;
    max-height: unset !important;
    height: 100%;
    width: 100%;
  }

  &.checkboxList{
    .productItem{
      padding-left: 50px;
    }
  }

  .productItem {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    padding: 10px 15px;
    align-items: center;
    font-size: 0.815rem;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    position: relative;
    //transition: all 0.4s ease;

    &:hover {
      background-color: #f8f8f8;
      @media screen and (max-width: 1024px) {
        background-color: #fff;
      }
    }
  }

  .productMore {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      background: linear-gradient(90deg, #6c6cc7, #5757b7);
      padding: 10px 20px;
      border-radius: 80px;
      cursor: pointer;
      width: 15%;
      transition: all 0.4s ease;
      opacity: 1;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        width: 50%;
      }
    }
  }

  .productItemOrder{
    color: #6c6cc7;
  }

  .productItemColumn {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    position: relative;
    width: 100%;
    max-height: 200px;
    .productItemColumnName {
      display: none;
    }

    &.checkbox{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      width: 20px;
      min-width: 20px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 1024px) {
    .productItem {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 5px 0;
      gap: 0;

      .productItemColumn {
        width: 95% !important;
        display: flex;
        justify-content: space-between !important;
        gap: 5px;
        padding: 0;

        &.buy{
          .productItemColumnName{
            opacity: 0;
            visibility: hidden;
          }
        }

        .productItemColumnName {
          display: block;
          width: 45%;
          text-align: start;
          padding: 7px 10px;
        }
        .productItemColumnValue{
          width: 50%;
          text-align: start;
          display: flex;
          align-items: center;
        }
      }
    }


    &.checkboxList {
      .productItem {
        padding: 35px 0 20px;
        .productItemColumn{
          padding: 0;
        }
        &.markups {
          .edit{
            position: absolute;
            top: 7px;
            left: 40px;
          }
        }
      }
      .checkbox {
        top: 20px;
        left: 10px;
      }
    }
  }
}
