.i_distributor-page{
  .i_distributor-tabs{
    margin-bottom: 15px;
  }
  .i_distributor-items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    .i_distributor-item{
      width: calc(100%/4 - 12px);

      @media screen and (max-width: 1410px){
        width: 32%;
      }

      padding: 20px 15px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      transition: all .4s ease;
      font-size: 0.875rem;

      .i_distributor-item-title{
        font-size: 1.125rem;
      }

      .i_distributor-item-industry{
        font-size: 0.75rem;
        padding: 5px 15px;
        border-radius: 50px;
        background: #f8f8f8;
        width: max-content;
      }

      .i_distributor-item-access{
        .i_distributor-item-access-status{
          &.blue{
            color: #0184db;
          }
          &.green{
            color: #01c716;
          }
        }
      }

      .i_distributor-item-connected{
        font-size: 0.875rem;
        color: #01c716;
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
        align-items: center;
        height: 44px;
      }

      .i_distributor-item-request{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          color: #6c6cc7;
          width: 170px;
          border: 2px solid #6c6cc7;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          border-radius: 80px;
          height: 44px;
          cursor: pointer;
        }
      }

      .i_distributor-item-bottom{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .i_distributor-item-industry{
          cursor: pointer;
        }
      }
      
      .i_distributor-btn{
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .i_distributor-item-actives-stats{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 15px;
      }

      .i_distributor-item-id{
        position: relative;
        margin-bottom: 15px;
        color: #b7b7b7;
        display: flex;
        align-items: center;
        gap: 35px;

        .i_clipboard{
          position: relative;
          button{
            padding: 0;
          }
        }
      }

      a{
        color: #0184db;
        text-decoration: none;
        font-weight: 500;
      }

    }
  }
  .i_distributor-page-skeleton{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

//MEDIA QUERIES

@media screen and (max-width: 768px){
  .i_distributor-page{
    .i_distributor-items{
      .i_distributor-item{
        width: 100%;

        .i_distributor-item-id{
          margin: 0;
        }
      }
    }
  }
}