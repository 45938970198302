.i_auth_form {
  padding: 40px;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  font-size: 1rem;

  .i_auth_form-title {
    font-size: 1.125rem;
  }

  .i_auth-img {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
    z-index: 1;
    width: 250px;
    height: 65px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .i_country-title{
      background: #f3f8fb;
      border-radius: 10px;
      position: absolute;
      right: 0;
      bottom: -10px;
      font-size: 13px;
      padding: 2px 10px 3px;
      color: #0184db;
    }
  }

  .i_auth_form-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: clamp(300px, 100%, 400px);

    .i_auth_form-item {
      position: relative;
      display: flex;
      justify-content: center;
      transition: all .2s;

      input {
        width: 100%;
        height: 44px;
        padding: 5px 10px;
        padding-left: 17px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 50px;
        outline: none;
      }

      label {
        transform: translateY(-60%);
        position: absolute;
        left: 18px;
        transition: all .2s ease;
        color: #757575;
        font-size: 0.75rem;
        top: 0;
        padding: 0 10px;
        background: #fff;
      }
      &.link{
        margin-top: 20px;
        text-align: center;
        transition: all .4s ease;
        span{
          color: #0184db;
          cursor: pointer;
        }
        &.disabled{
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }

    .i_error-message {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fe2942;
      font-size: 0.875rem;
      margin-top: 15px;
    }

    .i_auth_form-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.disabled{
        opacity: 0.4;
        pointer-events: none;
      }
      button{
        width: 100%;
      }
    }
  }

  .i_auth_form-forgot {
    margin-top: 20px;
    color: #0184db;
    cursor: pointer;
  }
}

.i_message{
  font-size: 0.875rem;
  &.green{
    color: #25AE88FF;
  }
  &.red{
    color: #fe2942;
  }
}

.i_form_success{
  z-index: 10;
  height: 100%;
  width: 100%;
  text-align: center;
  background: white;
  gap: 15px;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  color: #25ae88;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg{
    width: 100px;
    height: 100px;
  }
}

//HIDDEN
.hd {
  transform: translateY(-100px);
  opacity: 0;
  visibility: hidden;
  height: 0;
}