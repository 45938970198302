.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-align: center;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }
  .modalItems{
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }

  .modalItem{
    position: relative;
    opacity: 1;
    height: 100%;
    display: flex;
    transform: translateY(0);
    transition: all .4s ease;
    z-index: 1;

    @starting-style{
      opacity: 0;
      height: 0;
      z-index: -1;
      transform: translateY(-50px);
    }

    &:nth-child(1){
      .modalItemDelete{
        display: none;
      }
    }
  }

  .modalItemDelete{
    position: absolute;
    right: -5px;
    top: -5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: #f8f8f8;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    svg{
      width: 100%;
      height: 100%;
    }
  }

  .modalBtn{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .isDeleted{
    transform: translateY(-50px);
    opacity: 0;
    z-index: -1;
  }
}


