//MULTITOOLS
.catalogTools{
  height: 36px;
  padding: 0 12px;
  border-radius: 80px;
  border: 2px solid #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  &.catalog{
    height: unset;
    border: 0;
    gap: 10px;
    padding: 0;
  }

  &.hide{
    display: none;
  }
}