@import '../../../../styles/mixins';

// SETTINGS PAGE

.i_settings-page{
  position: relative;
  .i_settings-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    transition: all .4s ease;

    .i_settings-prices{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 15px;
      border-radius: 15px;
      border: 2px solid #f1f1f1;
      .i_settings-prices-title{
        color: #000;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .i_settings-prices-head{
        background-color: #f8f8f8;
        padding: 10px 15px;
        border-radius: 10px;
        .i_prices-head-items{
          display: flex;
          align-items: center;
          width: 94%;
          .i_prices-head-item{
            width: 25%;
            transition: all .4s ease;
            //@media screen and (max-width: 1280px){
            //  width: 35%;
            //}
          }
        }
      }
      .i_settings-prices-items{
        display: flex;
        flex-direction: column;
        .i_settings-prices-add{
          position: absolute;
          top: 15px;
          right: 28px;
          svg{
            width: 30px;
            height: 30px;
            fill: #7b7b7b;
            cursor: pointer;
            transition: all .4s ease;
            &:hover{
              fill: #fe2942
            }
          }
        }
        .i_settings-prices-item{
          border-bottom: 1px solid #ebebeb;
          padding: 10px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: all .4s ease;

          &:hover{
            background-color: #f8f8f8;
          }

          &:last-child{
            border-bottom: none;
          }

          .i_settings-prices-info{
            display: flex;
            width: 94%;
            text-wrap: nowrap;
            .i_settings-prices-info-item{
              width: 25%;

              @media screen and (min-width: 768px){
                text-wrap: auto;
                @include title_limit(1, 100%);
              }

              transition: all .4s ease;
              &.code{
              }
              //@media screen and (max-width: 1280px){
              //  width: 35%;
              //}
            }
          }

          .i_settings-prices-item-action{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 5%;
            gap: 20px;
            svg{
              width: 25px;
              height: 25px;
              fill: #7b7b7b;
              cursor: pointer;
              transition: all .4s ease;
              &:hover{
                fill: #fe2942
              }
            }
          }
        }
      }

      @media screen and (max-width: 768px){
        .i_settings-prices-head{
          .i_prices-head-items{
            display: none;
          }
        }
        .i_settings-prices-items{
          .i_settings-prices-info{
            flex-direction: column;
            gap: 10px;
          }
        }
      }

    }

    .i_settings-items{
      width: 100%;

      @media screen and (max-width: 1410px){
        width: 100%;
      }

      .i_settings-item{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px 15px;
        border: 2px solid #f1f1f1;
        border-radius: 15px;

        .i_settings-item-title{
          color: #000;
          font-size: 1.25rem;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .i_settings-item-value{
          position: relative;
          padding: 10px;
          background-color: #f8f8f8;
          border-radius: 15px;
          max-width: 350px;

          @media screen and (max-width: 560px){
            max-width: unset;
            width: 100%;
          }
        }

        .i_settings-item-showkey{
          position: absolute;
          right: 55px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          div{
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .i_settings-item-button{
          color: #fff;
          background: linear-gradient(90deg, #6c6cc7, #5757b7);
          padding: 10px 20px;
          border-radius: 80px;
          cursor: pointer;
          width: max-content;
          transition: all .4s ease;
          opacity: 1;

          &:hover{
            opacity: .8;
          }
        }

        .i_setting-item-alert{
          margin-top: 10px;
          color: #fe2942;
        }
      }
    }
  }
}
