.productHead {
  display: grid;
  grid-template-columns: repeat(28, 1fr); // три колонки
  grid-template-rows: auto; // высота строки будет автоматической
  align-items: center;
  font-size: 0.815rem;
  background-color: #f8f8f8;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  min-width: 1300px;

  @media screen and (max-width: 1024px){
    min-width: unset;
  }

  .productHeadItem {
    padding: 0 5px;
    &.disabled{
      opacity: .3;
      pointer-events: none;
    }
  }

  .active {
    grid-column: span 2;
    grid-row: span 1;
    display: flex;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
  }

  .name{
    grid-column: span 4;
    grid-row: span 1;
  }

  .markup{
    grid-column: span 3;
    grid-row: span 1;
  }

  .period{
    grid-column: span 4;
    grid-row: span 1;
  }

  .priority{
    grid-column: span 2;
    grid-row: span 1;
  }

  .condition{
    grid-column: span 6;
    grid-row: span 1;
  }

  .segmentation{
    grid-column: span 4;
    grid-row: span 1;
  }

  .checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    width: 20px;
    min-width: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .i-select-contain {
      display: flex;
      position: relative;
      padding-left: 0;
      margin-bottom: 0;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1024px) {
  .productHead {
    min-height: 40px;
    padding: 10px;
    .productHeadItem{
      display: none;
      &.showMobile{
        display: block;
      }
    }
    .active{
      opacity: 1;
      visibility: visible;
    }
  }
}
