.navigateBtn{
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .navigateBtnSvg{
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      transition: all .4s ease;
    }

    &:hover{
      svg{
        fill: #fe2942;
      }
    }

    &.markupsSvg{
      svg{
        path{
          stroke: #7b7b7b;
          transition: all .4s ease;
        }
      }
      &:hover{
        svg{
          fill: none;
          path{
            stroke: #fe2942;
          }
        }
      }
    }
  }
}