.exportCatalog{
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  svg{
    width: 18px;
    height: 18px;

    path{
      fill: #7b7b7b;
      stroke: #7b7b7b;
      transition: all .4s ease;
    }
  }

  &:hover{
    svg{
      path{
        fill: #fe2942;
        stroke: #fe2942;
      }
    }
  }
}