.productHead{
  display:flex;
  align-items: center;
  font-size: 0.815rem;
  background-color: #f8f8f8;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  text-wrap: nowrap;
  gap: 10px;

  &.checkboxList{
    padding-left: 50px;
  }

  &.notTouch{
    user-select: none;
  }

  .productHeadItem{
    position: relative;
    width: 150px;

    .productHeadItemContent{
      width: 100%;
      overflow: hidden;
    }

    &.active{
      span{
        opacity: 0;
        visibility: hidden;
      }
      .productHeadItemLine{
        display: none;
      }
    }

    &.checkbox{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      width: 20px;
      min-width: 20px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .i-select-contain {
        display: flex;
        position: relative;
        padding-left: 0;
        margin-bottom: 0;
        justify-content: center;
      }
    }

    &.buy{
      span{
        display: none;
      }
      .productHeadItemLine{
        display: none;
      }
    }

    .productHeadItemLine{
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 9px;
      background-color: #f8f8f8;
      cursor: col-resize;
      z-index: 1;
      &::before{
        content: '';
        position: absolute;
        right: 4px;
        top: 0;
        height: 0;
        width: 1px;
        z-index: 5;
        background-color: #b9b9b9;
        opacity: 0;
        visibility: hidden;
      }
      &::after{
        content: '';
        position: absolute;
        right: 4px;
        top: 0;
        height: 100%;
        width: 1px;
        z-index: 5;
        background-color: #b9b9b9;
      }
      &.active{
        &::before{
          height: 83vh;
          opacity: 1;
          visibility: visible;
        }
      }

    }
  }
}

@media screen and (max-width: 1024px) {
  .productHead {
    min-height: 40px;
    padding: 10px;
    width: 100% !important;
    .productHeadItem{
      display: none;
      &.showMobile{
        display: block;
      }
    }
    .active{
      opacity: 1;
      visibility: visible;
      span{
        display: block;
      }
    }
  }
}
