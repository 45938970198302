.currencyRate{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(50% - 15px);
  padding: 20px 15px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  min-height: 270px;
  max-height: 270px;
  @media screen and (max-width: 768px){
    width: 100%;
  }
  .currencyRateTitle{
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}