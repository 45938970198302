.i_profile{
  .i_profile-items{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
    padding: 20px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;

    @media screen and (max-width: 1024px){
      width: 100%;
      .i_profile-item{
        font-size: 0.875rem;
      }
    }

    .i_profile-edit{
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      fill: #7b7b7b;
      svg{
        width: 25px;
        height: 25px;
        transition: all .4s ease;
      }

      &:hover{
        svg{
          fill: #fe2942;
        }
      }
    }
    .i_profile-item{
      display: flex;
      gap: 5px;
      .i_profile-item-value{
        font-weight: 500;
      }
    }
  }
}