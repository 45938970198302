.addListItemButton {
  position: relative;
  width: 100%;
  height: 42px;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .addListItemButtonImg {
    width: 60px;
    height: 42px;
    position: relative;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
      z-index: 2;
      transform: rotate(90deg);
    }

    &::after {
      content: '';
      position: absolute;
      background-color: #f8f8f8;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      z-index: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #e1dfe1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}