.integrationItems{
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.integrationItemDisabled{
  opacity: 0.5;
  pointer-events: none;
}

.marketplacesItemFile{
  position: relative;
  display: flex;
  justify-content: start;
  font-size: 0.875rem;
  text-align: start;
  word-break: break-word;
  align-items: center;
  gap: 5px;

  span{
    text-wrap: nowrap;
  }

  a{
    word-break: break-word;
    text-decoration: none;
    color: #0184db;
  }
}