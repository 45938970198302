.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }
  .modalItems{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .modalBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .expandedContent{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
  }
}

.tabContent{
  padding: 0 !important;
}


