.borderBlock{
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  transition: height .2s ease;

  .borderBlockLabel{
    transform: translateY(-60%);
    position: absolute;
    left: 18px;
    transition: all 0.2s ease;
    color: #757575;
    font-size: 0.75rem;
    top: 0;
    padding: 0 10px;
    background: #fff;
  }
}