.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-align: center;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }
  .modalItems{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
}


