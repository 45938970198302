//OVERLAY
.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all .4s ease;

  &.overlayLevel_1 {
    z-index: 5;
  }

  &.overlayLevel_2 {
    z-index: 6;
  }

  &.overlayLevel_3 {
    z-index: 7;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}