@import "../../styles/mixins";

//MAIN

.i_main{
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px){
    height: calc(100vh - 130px);
    padding-bottom: 78px
  }
}

main{
  position: relative;
  transition: all .4s ease;
}

//CONTAINER

.container{
  width: 100%;
  padding: 20px 15px;
  height: 91vh;
  overflow-y: auto;
}

//SIDEBAR

.i_sidebar{
  width: 290px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #f8f8f8;
  gap: 70px;
  height: auto;
  padding: 25px;
  justify-content: space-between;
  transition: all .4s ease;
  &.close{
    width: 70px;
    padding: 25px 10px;
    .i_sidebar-items{
      .i_sidebar-item{
        //justify-content: center;
        a{
          justify-content: center;
        }
        span{
          display: none;
        }
        &.dealer-catalogs{
          .i_sidebar-subitems{
            a{
              padding: 5px 6px;
            }
            .dotted{
              display: none;
            }
            span{
              display: block;
            }
          }
        }
      }
      .i_sidebar-item-doc{
        opacity: 0;
        visibility: hidden;
      }
      .i_sidebar-item-telegram{
        opacity: 0;
        visibility: hidden;
      }
    }
    .i_sidebar-close{
      transform: rotate(0deg);
      right: -30%;
    }

  }
  .i_sidebar-upper{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    justify-content: space-between;
  }
  .i_sidebar-close{
    position: absolute;
    top: 60px;
    transform: rotate(180deg);
    right: -8%;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all .4s ease;
    img{
      margin-right: -2px;
      width: 12px;
      height: 12px;
      z-index: 2;
    }
    &::after{
      content: '';
      position: absolute;
      background-color: #f8f8f8;
      width: 37px;
      height: 37px;
      border-radius: 100%;
      z-index: 1;
    }

    @media screen and (max-width: 1300px){
      display: none;
    }
  }
  .i_sidebar-logo{
    width: 100%;
    height: 45px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .i_sidebar-bottom{
    //margin-top: 130px;
    width: 100%;
    .i_sidebar-items{
      width: 100%;
      .i_sidebar-item{
        width: 100%;
        display: flex;
        gap: 20px;
        &.language{
          align-items: flex-end;
          padding: 15px 25px;
          height: 100%;
          border-radius: 20px;
          &.open{
            background-color: #fff;
          }
        }
      }
    }
  }
  .i_sidebar-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
    font-size: 15px;
    transition: all .3s;
    .i_sidebar-item-doc{
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 5px;
      opacity: 1;
      visibility: visible;
      transition: all .4s ease;
      a{
        text-decoration: none;
        color: #0184db;
      }
    }
    .i_sidebar-item-telegram{
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      gap: 5px;
      transition: all .4s ease;
      svg{
        width: 35px;
        height: 35px;
      }
      a{
        text-decoration: none;
        color: #0184db;
      }
    }
    .i_sidebar-item{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 50px;
      border-radius: 30px;
      transition: background .4s, height .4s;
      .i_sidebar-help{
        position: absolute;

      }
      &:hover{
        background-color: #fff;
      }
      .i_sidebar-item-svg{
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          svg{
            fill: #fe2942;
          }
        }
      }

      &.dealer-catalogs{
        &.active{
          background-color: #fff;
          span{
            color: #000
          }
          svg{
            path{
              fill: #FF0000;
            }
          }
        }
      }

      &.active{
        background-color: #777;
        span{
          color: #fff
        }
        svg{
          path{
            fill: #fff;
          }
        }

        .i_sidebar-subitems{
          opacity: 1;
          visibility: visible;
          height: max-content;
          .i_sidebar-subitem{
            span{
              transition: all .4s ease;
            }
            &:hover{
              span{
                color: #fe2942;
              }
            }

            &.active{
              &:hover{
                span{
                  color: #fff;
                }
              }
            }
          }
        }
      }
      a{
        display: flex;
        align-items: center;
        gap: 20px;
        text-decoration: none;
        color: #000;
        padding: 15px 25px;
        width: 100%;
        transition: all .4s ease;
        cursor: pointer;
        max-height: 50px;
      }
      svg{
        width: 24px;
        height: 24px;
      }

      .i_sidebar-subitems{
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease;
        height: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0 15px;
        .i_sidebar-subitem{
          &.active{
            a{
              background-color: #777;
              span{
                color: #fff;
              }
            }
          }
          a{
            font-size: 0.8rem;
            display: flex;
            gap: 10px;
            padding: 6px 5px 5px 20px;
            border-radius: 30px;
            width: 100%;
            .dotted{
              display: block;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background-color: #aaa;
            }
          }
        }
      }
    }
  }
}

//TABBAR

//.i_tabbar{
//  position: fixed;
//  height: 75px;
//  bottom: 20px;
//  left: 50%;
//  transform: translateX(-50%);
//  background-color: #f8f8f8;
//  width: 90%;
//  border-radius: 50px;
//  z-index: 100;
//  display: none;
//  justify-content: center;
//  align-items: center;
//  .i_tabbar-items{
//    display: flex;
//    align-items: center;
//    justify-content: space-around;
//    width: 100%;
//    .i_tabbar-item{
//      border-radius: 100%;
//      position: relative;
//      a{
//        display: flex;
//        flex-direction: column;
//        justify-content: center;
//        align-items: center;
//        gap: 10px;
//        text-decoration: none;
//        font-size: 0.750rem;
//        color: #fe2942;
//        span{
//          display: none;
//        }
//      }
//      &::before{
//        content: "";
//        position: absolute;
//        background-color: transparent;
//        height: 65px;
//        width: 0;
//        border-radius: 50px;
//        top: 50%;
//        left: 50%;
//        transform: translate(-50%, -50%);
//        z-index: -1;
//        transition: all 0.4s ease;
//      }
//      .i_tabbar-item-svg{
//        div{
//          width: 26px;
//          height: 26px;
//        }
//        svg{
//          width: 26px;
//          height: 26px;
//          fill: #fe2942;
//        }
//      }
//
//      &.active{
//        &::before{
//          background-color: #fff;
//          width: 140px;
//        }
//        a{
//          span{
//            display: block;
//          }
//        }
//      }
//    }
//  }
//}

.i_tabbar{
  position: fixed;
  bottom: 0;
  border-top: 1px solid #f1f1f1;
  width: 100%;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  //&.dealer{
  //  .i_tabbar-items{
  //    gap: 15px;
  //    justify-content: unset;
  //  }
  //  .i_tabbar-item{
  //    width: calc(20% - 12px);
  //  }
  //}
  .i_tabbar-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    position: relative;
    z-index: 2;
    background-color: #f8f8f8;
  }
  .i_tabbar-item{
    border-radius: 100%;
    position: relative;
    width: calc(25% - 12px);
    display: flex;
    height: 47px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span{
      font-size: 0.625rem;
      color: #000;
    }
    a{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      font-size: 0.625rem;
      color: #000;
      height: 100%;
      span{
        display: block;
        word-break: break-all;
        text-align: center;
      }
      transition: all .4s ease;
    }

    .i_tabbar-item-svg{
      div{
        width: 24px;
        height: 24px;
      }
      svg{
        width: 24px;
        height: 24px;
        fill: #fe2942;
      }
      transition: all .4s ease;
    }

    &.active{
      a{
        color: #fe2942;
        span{
          display: block;
          color: #fe2942;
        }
      }
    }

    .i_basket{
      display: flex;
      margin-right: 0;

      svg{
        path{
          fill: #fe2942;
        }
      }
    }
  }
  .i_tabbar-more{
    position: absolute;
    bottom: 75px;
    left: 0;
    width: 100%;
    height: max-content;
    background-color: #fff;
    transform: translateY(400px);
    transition: all .4s ease;
    &.show{
      transform: translateY(0);
    }
    .header-items{
      position: absolute;
      top: 15px;
      right: 10px;
      a{
        text-decoration: none;
        color: #000;
      }
      .header-item.profile.active{
        .profile-head{
          span{
            display: block;
          }
        }
      }
      .header-item-profile{
        width: 49px !important;
      }
      .profile-head{
        span{
          display: none;
        }
      }
    }
  }
  .i_tabbar-more-items{
    padding: 75px 15px 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 15px;
    box-shadow: 0px -5px 5px 0px rgba(24, 24, 24, 0.07);
  }

  .i_tabbar-catalogs{
    position: absolute;
    bottom: 75px;
    left: 0;
    width: 100%;
    height: max-content;
    background-color: #fff;
    transform: translateY(400px);
    transition: all .4s ease;
    &.show{
      transform: translateY(0);
    }
    .i_tabbar-catalogs-items{
      display: flex;
      justify-content: space-between;
      padding: 15px;
      gap: 15px;
      box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
      .i_tabbar-item{
        width: 33%;
        a{
          display: flex;
          flex-direction: row;
          span{
            word-break: auto-phrase;
            text-align: start;
          }
        }
      }
      svg{
        fill: transparent;
      }
    }
  }

}

//MAIN

main{
  width: 100%;
}

//HEADER

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 35px 20px 35px;

  .item-value{
    text-decoration: none;
    color: #000;
    &:hover{
      span{
        color: #fe2942;
      }
    }
  }
  .header-left{
    max-width: 60%;
    @include title_limit(2);
    .header-title{
      font-size: 1.5rem
    }
  }
  .header-right{
    display: flex;
    align-items: center;
  }
}

.i_search-bar{
  display: flex;
  position: relative;
  width: 300px;
  margin: 0 15px;
  .i_search-bar-input{
    width: 100%;
  }
  input{
    width: 100%;
    height: 36px;
    border-radius: 80px;
    border: 0;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-size: 0.875rem;
    transition: all .4s ease;
    padding: 10px 36px 10px 15px;
    outline: none;
    &::placeholder{
      color: #aaa;
    }
  }
  .i_search-bar-btn{
    position: absolute;
    background-color: #fff;
    right: 0;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom-right-radius: 80px;
    border-top-right-radius: 80px;
    border-left: 0;
    padding-right: 10px;
    img{
      width: 20px;
      height: 20px;
    }
  }

  .i_search-bar-items{
    position: absolute;
    top: 50px;
    z-index: 4;
    background: white;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 15px;
    width: 200%;
    display: none;
    &.show{
     display: block;
    }
    .i_search-bar-container{
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: scroll;
      height: 100%;
      max-height: 300px;
      padding-right: 10px;

      &::-webkit-scrollbar {
        background-color: #fff; /* Цвет бегунка */
        border-radius: 30px; /* Округление бегунка */
        width: 3px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #767676;
        border-radius: 30px;
      }
    }
    .i_search-bar-item{
      cursor: pointer;
      .i_search-bar-item-name{
        font-size: 0.875rem;
        transition: all .4s ease;
      }
      &:hover{
        .i_search-bar-item-name{
          color: #fe2942;
        }
      }
      .i_search-bar-item-meta{
        font-size: 0.75rem;
        color: #aaa;
      }
    }
    .i_search-bar-item-empty{
        font-size: 0.75rem;
        color: #aaa;
    }
  }
}

.header-items {
  display: flex;
  gap: 15px;
}

/*Language Select*/

.custom-language-selector {
  position: relative;
}

.selected-language {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 20px;
}

.selected-language img {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  object-fit: cover;
}

.language-options {
  position: absolute;
  top: 120%;
  left: 20%;
  min-width: 100%;
  border-top: none;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;

  &.right{
    left: 120%;
    top: -100%;
  }
}

.language-option:first-child{
  border-radius: 20px 20px 0 0;
}

.language-option:last-child {
  border-radius: 0 0 20px 20px;
}

.dropdown-arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 24px;
  height: 24px;
  /*transition: all .3s ease;*/
}

.dropdown-arrow.open{
  transform: translateY(-50%) rotate(180deg);
}

.language-option {
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  z-index: 10;

}

.language-option img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 100px;
  object-fit: cover;
}

.language-option:hover {
  background-color: #f2f2f2;
}

.selected-language.open {
  border-radius: 5px 5px 0 0;
}

//Sidebar Language

.i_sidebar-language{
  position: relative;
  .i_sidebar-language-header{
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
      color: #fe2942;
    }
    img{
      width: 24px;
      height: 25px;
    }
  }
  .i_sidebar-language-options{
    position: absolute;
    z-index: 9;
    display: flex;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;
    &.open{
      opacity: 1;
      visibility: visible;
    }
    .i_sidebar-language-option{
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      transition: all .4s ease;
      &:hover{
          color: #fe2942;
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
  }
}

//COPY TO CLIPBOARD

.i_clipboard{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  height: 24px;

  button{
    background-color: unset;
    border: none;
    cursor: pointer;
    img{
      width: 24px;
      height: 24px;
    }
  }
}

.i_clipboard-notification{
  position: absolute;
  left: 110%;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(37, 174, 136);
  padding: 20px 10px;
  text-wrap: nowrap;
  color: white;
  border-radius: 80px;
  transition: all .4s ease;
}

//MULTITOOLS
.i_catalogtools{
  height: 36px;
  padding: 0 12px;
  border-radius: 80px;
  border: 2px solid #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .i_catalogtools-item{
    cursor: pointer;
    height: 26px;
    width: 26px;
    transition: all .4s ease;
    div{
      width: 26px;
      height: 26px;
    }
    &.disabled{
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.i_element-tools{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  margin-bottom: 10px;
  height: 42px;
  width: 100%;

  .i_element-tools-left{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .i_element-tools-right{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media screen and (max-width: 1300px){
    width: calc(100% - 60px);
  }

  @media screen and (max-width: 1024px){
    width: 100%;
  }

  .i_element-tools-item{
    cursor: pointer;
    height: 26px;
    width: 26px;
    transition: all .4s ease;
    cursor: pointer;
    div{
      width: 26px;
      height: 26px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &.disabled{
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
      svg{
        path{
          fill: #7b7b7b;
        }
      }
    }
  }
}

//Images Uploader

.i_images-uploader{
  width: 100%;
  height: 150px;
  position: relative;
  .zone{
    border: 2px dashed #ccc;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    .drop-zone{
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .drag-files{
        font-size: 1.125rem;
      }
    }
  }
}


//MEDIA QUERIES

@media (max-width: 1024px) {
  .container{
    padding: 20px 10px 0;
    height: 100%;
  }
  .i_sidebar{
    display: none;
  }
  .i_tabbar{
    display: flex;
  }

  .header{
    border-bottom: 1px solid #f1f1f1;
    padding: 15px;
    min-height: 54px;
  }

  .header-right{
    .i_multitools,
    .i_search-bar,
    .header-items{
      display: none;
    }
  }

  .header-left{
    max-width: 85%;
  }

  .header-title{
    span{
      font-size: 1.25rem;
    }
  }
}

