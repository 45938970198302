.markupsUnit{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  .markupsUnitSelect{
    width: 40%;
  }
  .markupsUnitRight{
    width: 59%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .markupsUnitRightItem{
    width: 70%;
  }
  .markupsUnitRightItemCurrency{
    width: 30%;
  }
  @media screen and (max-width: 600px){
    flex-direction: column;
    .markupsUnitSelect{
      width: 100%;
    }
    .markupsUnitRight{
      width: 100%;
    }
  }
}