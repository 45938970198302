.tabs{
  display: flex;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
  .tab{
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 25px;
    background-color: #f8f8f8;
    font-size: 0.875rem;
    transition: all .2s ease;
    &.active{
      color: #fff;
      background-color: #777;
    }
  }
}