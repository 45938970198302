.modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }

  .modalContent{
    width: 100%;

    textarea{
      min-height: 150px;
      font-size: 0.875rem;
    }
  }

  .modalFooterButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    button{
      width: 170px;
    }
  }
}