.emptyBlock{
  margin: 20px 0;
  position: relative;
  width: 400px;
  display: flex;
  gap: 15px;
  font-size: 0.815rem;
  flex-direction: column;
  padding: 20px 15px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  justify-content: center;

  @media screen and (max-width: 1024px){
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #0184db;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .emptyBlockTitle {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &.emptyBlockFull{
    width: 100%;
  }
}