.modal{
  display: flex;
  flex-direction: column;
  height: 100%;

  .modalTitle{
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }
  .modalItems{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .modalButtonKp{
    display: flex;
    button{
      padding: 0;
      span{
        font-size: 0.8125rem;
        width: max-content;
        color: #6c6cc7;
        border: 2px solid #6c6cc7;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        border-radius: 80px;
        height: 44px;
        padding: 10px 15px;
        cursor: pointer;
      }
    }
  }

  .modalFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding: 20px 0;
    width: 100%;

    .modalFooterTotal{
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .modalFooterTotalTitle{
      font-size: .875rem;
    }

    .modalTotal{
      font-size: 1.125rem;
    }

    .modalFooterButton{
      display: flex;
      align-items: center;
      gap: 15px;

      button{
        width: max-content;
        min-width: 170px;
        height: 44px
      }
    }

    .basketCloseOrder{
      font-size: 0.8125rem;
      width: max-content;
      color: #6c6cc7;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;

      svg{
        width: 16px;
        height: 16px;

        fill: #fe2942;
      }
    }

    @media screen and (max-width: 620px){
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px;
      .modalFooterTotal{
        order: 1;
        width: max-content;
      }
      .modalFooterButton{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        order: 2;

        .modalButtonKp{
          button span{
            width: 100%;
          }
        }
      }
    }
  }
}


