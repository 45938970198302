.radioButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .radioButtonInput {
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background: #fff;
    position: relative;
    transition: all 0.3s;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.3s;
    }
  }

  input:checked + .radioButtonInput {
    background: #5757b7;
    border-color: #5757b7;

    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    color: #333;
  }
}
