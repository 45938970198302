@import './mixins';

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-VariableFont_wght.ttf');
}

html{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  @media (max-width: 1200px) {
    font-size: 15px; /* уменьшаем для больших экранов */
  }

  @media (max-width: 992px) {
    font-size: 14px; /* уменьшаем для планшетов */
  }

  @media (max-width: 768px) {
    font-size: 13px; /* уменьшаем для маленьких экранов */
  }
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

* {
  &::-webkit-scrollbar {
    background-color: #fff; /* Цвет бегунка */
    border-radius: 30px; /* Округление бегунка */
    width: 2px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #767676;
    border-radius: 30px;
  }
}

.i_cabinet {
  width: 100%;
  height: 100%;
  position: relative;
  //overflow-y: hidden;
}

//I_MULTIFORM
.i_multi_modal {
  position: absolute;
  width: 40%;
  right: 0;
  background-color: white;
  height: 100vh;
  transition: all .3s ease;

  &.modal-level-1 {
    z-index: 6;
  }

  &.modal-level-2 {
    z-index: 7;
    width: calc(40% - 20px);
  }

  &.modal-level-3 {
    z-index: 8;
    width: calc(40% - 40px);
  }

  @media screen and (max-width: 1440px){
    &.modal-level-1 {
      width: 55%;
    }

    &.modal-level-2 {
      width: calc(55% - 20px);
    }

    &.modal-level-3 {
      width: calc(55% - 40px);
    }
  }

  @media screen and (max-width: 1024px) {
    width: 94% !important;
  }

  &.hide {
    right: -200%;
  }

  &.large {
    width: 1280px;

    &.modal-level-2 {
      width: calc(1280px - 20px);
    }

    &.modal-level-3 {
      width: calc(1280px - 40px);
    }

    @media screen and (max-width: 1280px) {
      width: 90%;
      &.modal-level-2 {
        width: calc(90% - 20px);
      }
      &.modal-level-3 {
        width: calc(90% - 40px);
      }
    }
  }

  .i_container {
    position: relative;
    padding: 25px;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1024px) {
      padding: 25px;
    }

    @media screen and (max-width: 768px) {
      padding-right: 10px;
    }

    .i_multi_modal-close {
      position: absolute;
      top: 20px;
      transform: rotate(180deg);
      left: -20px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      transition: all 0.4s ease;

      img {
        margin-right: -2px;
        width: 18px;
        height: 18px;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: #f8f8f8;
        width: 37px;
        height: 37px;
        border-radius: 100%;
        z-index: 1;
      }
    }
  }
}

//===========================MODAL

.i_modal-error {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  color: #fe2942;
}

//I_SELECT_MODAL

.i_select-role-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 15px;
  overflow-y: auto;
  height: 101%;

  .i_select-role-modal-title {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
  }

  .i_select-role-modal-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .i_select-role-modal-industry {
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #ccc;
    position: relative;

    .i_select-role-modal-industry-items {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-height: 375px;
      overflow-y: auto;
    }

    .i_select-role-modal-industry-title {
      transform: translateY(-60%);
      position: absolute;
      left: 18px;
      transition: all 0.2s ease;
      color: #757575;
      font-size: 0.75rem;
      top: 0;
      padding: 0 10px;
      background: #fff;
      width: max-content;
    }
  }

  .i_select-role-modal-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    transition: all .2s;
    position: relative;

    input {
      width: 100%;
      height: 44px;
      padding: 5px 10px;
      padding-left: 17px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 50px;
      outline: none;
      transition: all .4s ease;
    }

    label {
      transform: translateY(-60%);
      position: absolute;
      left: 18px;
      transition: all .2s ease;
      color: #757575;
      font-size: 0.75rem;
      top: 0;
      padding: 0 10px;
      background: #fff;
    }
  }

  .i_select-role-modal-error {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    color: #fe2942;
  }

  .i_select-role-modal-btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//ADD CATALOG
.i_add-catalog-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-align: center;

  .i_add-catalog-modal-title {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }

  .i_add-catalog-modal-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    .i_select-dropdown-body{
      text-align: start;
    }
  }

  .i_add-catalog-modal-code {
    text-align: start;
    margin-bottom: 10px;
  }

  .i_add-catalog-modal-item {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    transition: all .2s;

    //label {
    //  transform: translateY(-60%);
    //  position: absolute;
    //  left: 18px;
    //  transition: all .2s ease;
    //  color: #757575;
    //  font-size: 0.75rem;
    //  top: 0;
    //  padding: 0 10px;
    //  background: #fff;
    //}
    //
    //input[type='text'] {
    //  width: 100%;
    //  height: 44px;
    //  font-size: 1rem;
    //  border: 1px solid #ccc;
    //  border-radius: 50px;
    //  outline: none;
    //  transition: all .4s ease;
    //  padding: 5px 10px 5px 17px;
    //}
  }

  .i_add-catalog-modal-error {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    color: #fe2942;
  }

  .i_add-catalog-modal-btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

//EDIT PROFILE MODAL

.i_edit-profile-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-align: center;

  .i_edit-profile-title {
    font-size: 1.125rem;
    margin-bottom: 25px;
    width: 100%;
  }

  .i_edit-profile-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .i_edit-profile-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .i_edit-profile-btn {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
}

//ADD PRICE MODAL

.i_add-price-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-align: center;

  .i_add-price-modal-title {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 25px;
    width: 100%;
  }

  .i_add-price-modal-code {
    margin-bottom: 20px;
  }

  .i_add-price-modal-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .i_add-price-dealers {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    border: 2px solid #f1f1f1;

    .i_add-price-dealers-items {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-height: 520px;
      height: 100%;
      overflow-y: auto;
    }
  }

  .i_add-price-modal-btn {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
}

//PRODUCT DETAILS MODAL

.i_modal-in-skeleton {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  padding-right: 25px;

  .react-loading-skeleton:nth-child(1) {
    width: 34%;
  }

  .react-loading-skeleton:nth-child(2) {
    width: 63%;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 25px;
    padding-right: 0px;
    .react-loading-skeleton:nth-child(1) {
      width: 100%;
    }

    .react-loading-skeleton:nth-child(2) {
      width: 100%;
    }
  }
}

.i_product-detail {
  height: 100%;

  .i_product-detail-title {
    font-size: 1.2rem;
    width: 95%;
  }

  .i_product-detail-content {
    margin-top: 40px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    padding-right: 25px;
    justify-content: space-between;
    align-content: flex-start;
    //overflow: auto;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 25px;
      margin-top: 25px;
    }

    @media screen and (max-width: 768px) {
      gap: 25px;
      padding-right: 10px;
    }

    .i_product-detail-content-title {
      position: relative;
      width: max-content;
      font-size: 1.3rem;
    }

    .i_product-detail-content-left {
      position: relative;
      width: 34%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        padding-bottom: 25px;
      }
    }

    .i_product-detail-quantity_counter{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      background-color: #f8f8f8;
      font-size: 0.875rem;
      padding: 10px 15px;
      margin-top: 15px;
      margin-right: 24px;

      @media screen and (max-width: 1024px) {
        margin-right: 0;
      }
    }

    .i_product-detail-images-edit {
      position: absolute;
      z-index: 3;
      top: 2px;
      right: 2px;
      border-radius: 15px;
      padding: 20px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      cursor: pointer;
      opacity: 1;
      visibility: visible;
      transition: all .4s ease;

      @media screen and (max-width: 1024px) {
        opacity: 1;
        visibility: visible;
      }

      .i_product-detail-edit {
        div {
          width: 24px;
          height: 24px;

          svg {
            width: 100%;
            height: 100%;
            fill: #7b7b7b;
            transition: all .4s ease;
          }
        }

        &:hover {
          svg {
            fill: #fe2942;
          }
        }
      }

    }

    .i_product-detail-content-right {
      width: 63%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    .i_product-detail-images {
      width: 100%;
      height: max-content;

      .i_product-detail-noimage {
        height: 400px;
        width: 100%;
        border: 1px solid #e1dfe1;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 50%;
          }
        }

        @media screen and (max-width: 1024px){
          height: 300px;
        }
      }
    }

    .i_product-detail-props {
      width: 100%;
      height: max-content;

      .i_product-props-empty {
        margin: 15px 0;
        position: relative;
        width: 400px;
        display: flex;
        gap: 15px;
        font-size: 0.815rem;
        flex-direction: column;
        padding: 20px 15px;
        border: 2px solid #f1f1f1;
        border-radius: 15px;
        justify-content: center;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        a {
          text-decoration: none;
          color: #0184db;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .i_product-props-empty-title {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }

      .i_product-detail-props-title {
        font-size: 1.4rem;
        margin-bottom: 20px;
      }

      .i_product-detail-props-items {
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;

        .i_product-detail-props-item {
          display: flex;
          justify-content: space-between;
          //padding: 10px 0;
          &:first-child {
            padding-top: 0;

            .i_product-detail-props-item-value {
              border-radius: 15px 15px 0 0;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1dfe1;
          }

          &:last-child {
            .i_product-detail-props-item-value {
              border-radius: 0 0 15px 15px;
            }
          }

          .i_product-detail-props-item-name {
            width: 49%;
            padding: 10px 0;
          }

          .i_product-detail-props-item-value {
            width: 49%;
            padding: 10px 15px;
            background-color: #f8f8f8;
            overflow-wrap: break-word;
          }
        }
      }
    }

    .i_product-detail-description {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 40px;
      gap: 20px;
      position: relative;

      .i_tabs-header-item-edit{
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg{
          width: 20px;
          height: 20px;
          fill: #7b7b7b;
          transition: all .4s ease;
        }
        &:hover{
          svg{
            fill: #fe2942;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        margin-top: 30px;
      }

      @media screen and (max-width: 560px) {
        font-size: 0.875rem;
      }

      .i_product-description-empty {
        margin: 15px 0;
        position: relative;
        width: 400px;
        display: flex;
        gap: 15px;
        font-size: 0.815rem;
        flex-direction: column;
        padding: 20px 15px;
        border: 2px solid #f1f1f1;
        border-radius: 15px;
        justify-content: center;

        @media screen and (max-width: 560px) {
          width: 100%;
        }

        a {
          text-decoration: none;
          color: #0184db;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .i_product-description-empty-title {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }

    .i_product-detail-quantity {
      margin-top: 24px;
      font-size: 0.875rem;

      .i_product-detail-quantity-item {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid #e1dfe1;
        }

        .i_product-detail-quantity-item-name {
          width: 49%;
          padding: 10px 0;
        }

        .i_product-detail-quantity-item-value {
          width: 49%;
          padding: 10px 15px;
          background-color: #f8f8f8;
          border-radius: 15px;
        }
      }

      .i_product-detail-quantity-item-order{
        color: #6c6cc7;
      }
    }

    .i_product-detail-price {
      margin-top: 24px;

      .i_product-detail-price-items {
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;

        .i_product-detail-price-item {
          display: flex;
          justify-content: space-between;

          &.alone-item {
            .i_product-detail-price-item-value {
              border-radius: 15px !important;
            }
          }

          &:first-child {
            padding-top: 0;

            .i_product-detail-price-item-value {
              border-radius: 15px 15px 0 0;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1dfe1;
          }

          &:last-child {
            .i_product-detail-price-item-value {
              border-radius: 0 0 15px 15px;
            }
          }

          .i_product-detail-price-item-name {
            width: 49%;
            padding: 10px 0;
          }

          .i_product-detail-price-item-value {
            width: 49%;
            padding: 10px 15px;
            background-color: #f8f8f8;
            align-items: center;
            gap: 10px;
            display: flex;
            flex-direction: row;

            .i_product-detail-price-item-info{
              width: 20px;
              height: 20px;
              cursor: pointer;
              svg{
                width: 100%;
                height: 100%;
              }
            }
          }

        }
      }
    }

  }

  .i_show-more {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 42px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.show {
      .i_show-more-img {
        img {
          transform: rotate(-90deg);
        }
      }
    }

    .i_show-more-img {
      width: 60px;
      height: 42px;
      position: relative;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
        z-index: 2;
        transform: rotate(90deg);
      }

      &::after {
        content: '';
        position: absolute;
        background-color: #f8f8f8;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        z-index: 1;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #e1dfe1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.i_add-category-modal {
  height: 96%;

  .i_add-category-modal-title {
    text-align: center;
    font-size: 1.125rem;
    width: 100%;
    margin-bottom: 25px;
  }

  .i_add-modal-description{
    font-size: 0.75rem;
    color: #9a9a9a;
  }

  .i_add-category-content {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    height: 95%;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 15px;
    padding-top: 5px;

    .i_add-category-list {
      display: flex;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 20px;
      padding: 9px 10px 10px;
      position: relative;
      height: 44px;
      transition: height .2s ease;

      &.open {
        height: 82%;

        .i_add-category-list-content {
          height: 100%;
          visibility: visible;
          opacity: 1;

          &.add-item{
            height: 500px;
          }
        }

        .i_add-category-list-content-search {
          opacity: 1;
          visibility: visible;
        }
      }

      .i_add-category-list-content-search {
        opacity: 0;
        visibility: hidden;
      }

      .i_add-category-list-title {
        transform: translateY(-60%);
        position: absolute;
        left: 18px;
        transition: all 0.2s ease;
        color: #757575;
        font-size: 0.75rem;
        top: 0;
        padding: 0 10px;
        background: #fff;
      }

      .i_add-category-list-content {
        overflow-y: auto;
        padding: 15px;
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition: all .4s ease;
      }

      .i_add-category-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px 15px 7px;
        transition: all 0.4s ease;

        .i_add-category-parent-edit {
          cursor: pointer;

          div {
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .i_catalog-category-item-level-line {
        position: absolute;
        left: 1px;
        width: 1px;
        background-color: #d1d1d1;
        height: calc(100% - 32px);
        top: 15px;
      }

      .i_catalog-category-item {
        position: relative;
        font-size: 0.815rem;
      }

      .i_catalog-category-item-title {
        padding: 10px 0;
        position: relative;
        transition: all .4s ease;

        &:hover {
          color: #fe2942;
          cursor: pointer;
        }

        .i_catalog-title-circle {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          border-radius: 100%;
          left: -20px;
          background-color: #ebebeb;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          z-index: 1;

          img {
            width: 6px;
            height: 6px;
            opacity: .6;
          }

          &.active {
            &::after {
              content: "";
              width: 6px;
              height: 6px;
              background-color: #767676;
              border-radius: 100%;
              position: absolute;
              top: 50%;
              left: 4px;
              transform: translate(0%, -50%);
            }
          }
        }
      }

      .i_catalog-category-item-level-0 {
        padding-left: 15px;
        position: relative;

        .i_catalog-category-item-level-1 {
          display: none;
        }

        &.active {
          .i_catalog-category-item-level-1 {
            display: block;
          }
        }
      }

      @for $i from 1 through 10 {
        .i_catalog-category-item-level-#{$i} {
          position: relative;

          .i_catalog-category-item-level-#{$i + 1} {
            display: none;
          }

          &.active {
            .i_catalog-category-item-level-#{$i + 1} {
              display: block;
            }
          }
        }
      }
    }


    .i_add-category-items {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-direction: column;
    }

    .i_add-category-modal-btn {
      margin: 0 auto;
    }

    .i_add-category-modal-error {
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
      color: #fe2942;
    }
  }
}

.i_modal-catalog-category {
  border: 2px solid #f1f1f1;
  width: 100%;
  padding: 10px;
  font-size: 1.125rem;
  border-radius: 15px;
  height: 100%;

  .i_catalog-tools {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 8px;

    .i_catalogtools {
      height: unset;
      border: 0;
      gap: 10px;
      padding: 0;

      .i_catalogtools-item {
        width: 24px;
        height: 24px;
        cursor: pointer;

        &.disabled {
          opacity: 1;

          svg {
            path {
              fill: #b7b7b7;
              transition: all .4s ease;
            }
          }
        }

        div {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .i_catalog-tools-right {
      display: flex;
      gap: 10px;
    }

    .i_tools-item {
      cursor: pointer;

      div {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}

.i_edit-product-images {
  position: relative;
  height: 100%;
  width: 100%;

  .i_edit-product-images-title {
    font-size: 1.25rem;
    width: 95%;
  }

  .i_edit-product-images-content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 93%;
    overflow: auto;
    padding-right: 25px;

    .i_edit-product-images-content-title {
      font-size: 1.125rem;
      margin-bottom: 25px;
    }

    .i_edit-product-images-items {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;

      .i_edit-product-images-delete {
        position: absolute;
        width: 26px;
        height: 26px;
        background-color: #f8f8f8;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -10px;
        top: -5px;
        opacity: 1;
        visibility: visible;
        transition: all .4s ease;
        cursor: pointer;

        div {
          div {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .i_edit-product-images-item {
        position: relative;
        width: 150px;
        height: 150px;
        border: 1px solid #e1dfe1;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
        }
      }

      .i_edit-product-images-empty {
        margin: 15px 0;
        position: relative;
        width: 400px;
        display: flex;
        gap: 15px;
        font-size: 0.815rem;
        flex-direction: column;
        padding: 20px 15px;
        border: 2px solid #f1f1f1;
        border-radius: 15px;
        justify-content: center;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        a {
          text-decoration: none;
          color: #0184db;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .i_edit-product-images-empty-title {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }
  }

  .i_edit-product-images-save {
    position: relative;
    width: 100%;
    bottom: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.i_select-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .i_select-modal-title {
    font-size: 1.125rem;
    margin-bottom: 20px;
    width: 100%;
  }

  .i_select-modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 15px;

    .i_select-modal-items {
      display: flex;
      justify-content: space-between;
      height: 90%;
      width: 100%;
      .i_select-modal-item {
        width: 68%;

        .i_catalog-product-head{
          .i_catalog-product-head-item{
            &.name{
              width: 48%;
            }
            &.article{
              width: 20%;
            }
            &.inner_code{
              width: 20%;
            }
          }
        }

        .i_catalog-product-items{
          max-height: 95%;

          &.selected-list{
            overflow: unset;
          }
        }

        .i_catalog-product-item{
          gap: 10px;
          .i_catalog-product-item-title{
            display: none;
          }
          .i_catalog-product-item-name{
            width: 50%;
          }
          .i_catalog-product-item-article{
            width: 20%;
          }
          .i_catalog-product-item-inner_code{
            width: 20%;
          }
          .i_catalog-product-item-select{
            width: 11%;
            font-size: 0.75rem;
            color: #fff;
            border-radius: 25px;
            padding: 5px 9px;
            background-color: #6c6cc7;
            text-align: center;
            text-wrap: nowrap;

            &.selected{
              background-color: #01c716;
            }
          }
          @media screen and (max-width: 1024px){
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 10px 0px;
            .i_catalog-product-item-title{
              display: block;
              text-wrap: nowrap;
              margin-right: 5px;
              width: 35%;
            }
            .i_catalog-product-item-name,
            .i_catalog-product-item-article,
            .i_catalog-product-item-inner_code
            {
              display: flex;
              gap: 0;
              span{
                width: 60%;
              }
            }
          }
        }
        &.category {
          width: 30%;
          border: 2px solid #f1f1f1;
          padding: 8px 10px;
          font-size: 1.125rem;
          border-radius: 15px;
          .i_catalog-category-items{
            height: 80% ;
          }

          .i_catalog-tools {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            border-bottom: 2px solid #f1f1f1;
            padding-bottom: 6px;

            .i_catalogtools {
              height: unset;
              border: 0;
              gap: 10px;
              padding: 0;

              .i_catalogtools-item {
                width: 24px;
                height: 24px;
                cursor: pointer;

                &.disabled {
                  opacity: 1;

                  svg {
                    path {
                      fill: #b7b7b7;
                      transition: all .4s ease;
                    }
                  }
                }

                div {
                  width: 24px;
                  height: 24px;

                  svg {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }

            .i_catalog-tools-right {
              display: flex;
              gap: 10px;
            }

            .i_tools-item {
              cursor: pointer;

              div {
                width: 24px;
                height: 24px;

                svg {
                  width: 24px;
                  height: 24px;
                }
              }

              &.disabled {
                opacity: .5;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px){
    .i_select-modal-item.category{
      display: none;
    }
    .i_select-modal-items{
      flex-direction: column;
    }

    .i_select-modal-item{
      width: 100% !important;
    }

    .i_catalog-product-head{
      display: none;
    }

    .selected-list{
      .i_catalog-product-item{
        .i_catalog-product-item-name,
        .i_catalog-product-item-article,
        .i_catalog-product-item-inner_code{
          width: 100% !important;
        }
        .i_catalog-product-item-select{
          width: 150px !important;
          display: block;
          span{
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.i_catalog-category-items {
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow-y: auto;
  //background-color: #f8f8f8;
  padding: 0 15px 15px;
  margin-top: 15px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(118, 118, 118, 0.3);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #767676FF;
      transition: all .4s ease;
    }
  }


  .i_catalog-category-item-level-line {
    position: absolute;
    left: 1px;
    width: 1px;
    background-color: #d1d1d1;
    height: calc(100% - 32px);
    top: 15px;
  }

  .i_catalog-category-item {
    position: relative;
    font-size: 0.815rem;
  }

  .i_catalog-category-item-select {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: .625rem;
    color: #fff;
    border-radius: 25px;
    padding: 3px 7px;
    background-color: #6c6cc7;
    transform: translateY(-50%);
    &.selected{
      background-color: #01c716;
    }
  }

  .i_catalog-category-item-title {
    padding: 10px 0;
    position: relative;
    transition: all .4s ease;

    &:hover {
      color: #fe2942;
      cursor: pointer;
    }

    &.selected {
      color: #fe2942;
    }

    .i_catalog-title-circle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      border-radius: 100%;
      left: -20px;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      z-index: 1;

      img {
        width: 6px;
        height: 6px;
        opacity: .6;
      }

      &:not(.last-child) {
        &.active {
          &::after {
            content: "";
            width: 6px;
            height: 6px;
            background-color: #767676;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translate(0%, -50%);
          }
        }
      }
    }
  }

  .i_catalog-category-item-level-0 {
    padding-left: 15px;
    position: relative;

    .i_catalog-category-item-level-1 {
      display: none;
    }

    &.active {
      .i_catalog-category-item-level-1 {
        display: block;
      }
    }
  }

  @for $i from 1 through 10 {
    .i_catalog-category-item-level-#{$i} {
      position: relative;

      .i_catalog-category-item-level-#{$i + 1} {
        display: none;
      }

      &.active {
        .i_catalog-category-item-level-#{$i + 1} {
          display: block;
        }
      }
    }
  }
}

.i_catalog-product-head {
  display: flex;
  align-items: center;
  font-size: 0.815rem;
  background-color: #f8f8f8;
  padding: 10px 15px;
  border-radius: 10px;

  @media screen and (max-width: 1024px) {
    min-width: 100%;
    margin-bottom: 5px;
    padding: 3px;
    border-radius: 25px;
  }


  .i_catalog-product-head-item {
    width: 20%;

    &.active {
      width: 10%;
    }

    &.conditions {
      width: 30%;
    }

  }
}

.i_catalog-product-items {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 83vh;

  @media screen and (max-width: 1024px) {
    min-width: 100%;
    max-height: unset;
  }

  //gap: 30px;
  .i_catalog-product-item {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    font-size: 0.815rem;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    position: relative;
    transition: all .4s ease;

    &:hover {
      background-color: #f8f8f8;
    }

    @media screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(14, 1fr); // три колонки
      grid-template-rows: auto; // высота строки будет автоматической
      gap: 15px 10px;
      &:hover {
        background-color: unset;
      }
    }
  }

  .i_catalog-product-more {
    width: 100%;
    margin-top: 15px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    height: 2.7rem;

    span {
      max-width: 150px;
      color: #fff;
      background: linear-gradient(90deg, #6c6cc7, #5757b7);
      padding: 10px 20px;
      border-radius: 80px;
      cursor: pointer;
      width: 15%;
      transition: all 0.4s ease;
      opacity: 1;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        width: 50%;
      }
    }
  }

  .i_catalog-product-item > * {
    width: 20%;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .i_catalog-product-item-active {
    width: 10%
  }

  .i_catalog-product-item-markups-conditions {
    width: 30%;
  }

  .i_catalog-product-item-markups-edit{
    width: 10% !important;
  }

  @media screen and (max-width: 1024px) {
    .i_catalog-product-item-active {
      width: 100%;
      grid-column: span 1; // растягивает элемент на две колонки
      grid-row: span 1; // растягивает элемент на две строки
      justify-content: start;
      padding: 0;
    }

    .i_catalog-product-item-name,
    .i_catalog-product-item-price,
    .i_catalog-product-item-quantity {
      display: flex;
      gap: 5px;
    }

    .i_catalog-product-item-quantity {
      width: 100%;
      grid-column: span 5; // растягивает элемент на две колонки
      padding: 0;
      margin-left: calc(17% + 10px);
    }

    .i_catalog-product-item-price {
      width: 100%;
      grid-column: span 9; // растягивает элемент на две колонки
    }

    .i_catalog-product-item-name {
      grid-column: span 13; // растягивает элемент на две колонки
      grid-row: span 1; // растягивает элемент на две строки
    }

    .i_catalog-product-item-title {
      display: block;
    }

    .i_catalog-product-item-name {
      width: 100%;
    }
  }
}

//=============================!MODAL


//HIDE

.hide {
  opacity: 0;
  visibility: hidden;
}

.idn {
  display: none;
}

//============================MULTIPOPUP

.i_multi_popup {
  position: absolute;
  z-index: 6;
  width: 360px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;

  &.large {
    width: 600px;
    height: 230px;
  }

  .i_container {
    padding: 20px 30px;
    position: relative;
    height: 100%;
    box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
    border-radius: 15px;

    .i_multi_popup-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      border: 1px solid #ededed;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

//======================POPUP ITEMS

//DELETE DISTRIBUTOR CATALOG POPUP

.i_delete-distributor-catalog-popup {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .i_popup-title {
    padding-right: 35px;
  }

  .i_popup-buttons {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;

    .i_popup-button-success,
    .i_popup-button-cancel {
      width: 47%;

      button {
        height: 44px;
        width: 100%;
        padding: 0 13px;
        border-radius: 30px;
        outline: none;
        cursor: pointer;
      }
    }

    .i_popup-button-success {
      button {
        border: 2px solid #eee;
        color: #000;
        background-color: white;
      }
    }

    .i_popup-button-cancel {
      button {
        color: white;
        background-color: red;
        border: 2px solid red;
      }
    }
  }
}

//BUTTONS

.button-loader {
  position: relative;
  padding: 10px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  display: block;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: 0 solid #6c6cc7;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all .4s ease;
  z-index: 2;
  height: 2.7rem;
  width: 150px;

  span {
    position: relative;
    z-index: 3;
  }

  &.loader {
    pointer-events: none;

    &::after {
      content: '';
      width: 0;
    }

    span {
      opacity: 0;
      visibility: hidden;
    }
  }

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, #6c6cc7, #5757b7);
    z-index: 1;
    height: 100%;
    transition: all .2s ease;
  }

  &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('/images/loader.svg');
    background-size: 30px;
  }

  &:hover {
    opacity: .8;
  }
}

//CUSTOM SELECT CHECKBOX
.i-select-contain *,
.i-select-contain *::before,
.i-select-contain *::after {
  box-sizing: content-box !important;
}

.i-select-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.i-select-contain span {
  line-height: 1.5;
  font-size: 0.8rem;
  height: 100%;
  display: flex;
  font-family: inherit;
  align-items: center;
}

.i-select-contain {
  display: table;
  position: relative;
  padding-left: 1.6rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-bottom: 0.4rem;
  height: 20px;
}

.i-select-contain input[type="checkbox"] ~ .i-select-input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 1rem;
  width: 1rem;
  background: rgba(249, 249, 249, 0);
  transition: all 0.4s ease;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.i-select-contain input[type="checkbox"] ~ .i-select-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 0.2rem;
  height: 0.5rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: all 0.4s ease;
  transform: rotate(45deg);
  @media screen and (max-width: 1024px){
    left: 5px;
    top: 2px;
    width: 2px;
    height: 6px;
  }
}

.i-select-contain input[type="checkbox"]:disabled ~ .i-select-input::after {
  border-color: #ffffff;
}

.i-select-contain input:checked ~ .i-select-input::after {
  display: block;
}

.i-select-contain:hover input[type="checkbox"]:not([disabled]) ~ .i-select-input,
.i-select-contain input[type="checkbox"]:focus ~ .i-select-input {
  background: #fff;
  border-color: #777;
}

.i-select-contain input:focus ~ .i-select-input {
  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0);
}

.i-select-contain input[type="checkbox"]:checked ~ .i-select-input {
  background: #5757B7;
  border-color: #5757B7;
}

.i-select-contain input[type="checkbox"]:disabled ~ .i-select-input {
  opacity: 0.5;
  cursor: not-allowed;
}


.i-select-contain:hover input[type="checkbox"]:not([disabled]):checked ~ .i-select-input,
.i-select-contain input[type="checkbox"]:checked:focus ~ .i-select-input {
  background: #5757B7;
  border-color: #5757B7;
}

//I SELECT DROPDOWN
.i_select-dropdown {
  position: relative;
  font-size: 0.875rem;

  .i_select-dropdown-head {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 50px;
    outline: none;
    transition: all 0.4s ease;
    padding-left: 17px;
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .i_select-dropdown-head-label {
      transform: translateY(-60%);
      position: absolute;
      left: 18px;
      transition: all 0.2s ease;
      color: #757575;
      font-size: 0.75rem;
      top: 0;
      padding: 0 10px;
      background: #fff;
    }

    .i_select-dropdown-arrow {
      position: absolute;
      right: 20px;
      top: 52%;
      transform: translateY(-50%);

      &.active {
        svg {
          transform: rotateZ(-90deg);
        }
      }

      svg {
        width: 13px;
        height: 13px;
        transform: rotateZ(90deg);
        transition: all 0.2s ease;
      }
    }
  }

  .i_select-dropdown-title{
    @include title_limit(1, 75%);
  }

  .i_select-dropdown-body {
    padding: 10px;
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 15px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;

    .i_select-dropdown-body-items {
      max-height: 200px;
      overflow-y: auto;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .i_select-dropdown-body-item {
      padding: 10px 20px;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
}

//I_MULTITOOLS

.i_multitools {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 80px;
  padding: 0 10px;
  height: 36px;
  justify-content: center;
  align-items: center;

  .i_multitools-item {
    width: 18px;
    height: 18px;
    cursor: pointer;

    &.attach-item {
      padding-left: 1px;
      margin-bottom: 3px;
    }

    &.disabled {
      opacity: 1;

      svg {
        path {
          fill: #b7b7b7;
          transition: all .4s ease;
        }
      }
    }

    div {
      width: 18px;
      height: 18px;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #fe2942;
          transition: all .4s ease;
        }
      }
    }
  }
}


//I_SEARCH-HIGHLIGHT

.text-content {
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
}

mark {
  background-color: yellow;
}

mark.current {
  background-color: orange;
}

.i_search-highlight {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    border: 0;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    padding-left: 0;
    margin-left: 12px;
    outline: none;
    width: 85%;
    font-size: 0.875rem;
  }

  .i_search-highlight-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      div {
        width: 20px;
        height: 20px;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: #fe2942;
      }
    }
  }
}

//DATE TIME PICKER
.i_date-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.2s;
  width: 100%;

  .react-datepicker-popper{
    z-index: 3;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    .react-datepicker__triangle {
      fill: #f8f8f8;
      color: #f8f8f8;
    }
  }

  .date-picker-wrapper {
    position: relative;
    width: 100%;
  }

  .date-picker-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }

  .react-datepicker {
    border-radius: 15px;

    &-wrapper {
      width: 100%;

      input {
        width: 100%;
        height: 44px;
        font-size: 0.875rem;
        border: 1px solid #ccc;
        border-radius: 50px;
        outline: none;
        transition: all 0.4s ease;
        padding: 5px 10px 5px 17px;
      }
    }

    &__header {
      background-color: #f8f8f8;
      border-bottom: 0;

      &--time {
        border-top-right-radius: 15px;
      }

      &--has-time-select {
        border-top-left-radius: 15px;
      }
    }

    &__time-container {
      .react-datepicker__time {
        border-bottom-right-radius: 15px;

        .react-datepicker__time-box {
          border-bottom-right-radius: 15px;
          width: 84px;

          ul.react-datepicker__time-list {
            li.react-datepicker__time-list-item--selected {
              background-color: #5757b7;
              color: #fff;

              &:hover {
                background-color: #5757b7;
                color: #fff;
              }
            }
          }
        }
      }
    }

    &__day--selected,
    &__day--keyboard-selected,
    &__day--selected:not([aria-disabled="true"]):hover,
    &__day--in-selecting-range:not([aria-disabled="true"]):hover,
    &__day--in-range:not([aria-disabled="true"]):hover,
    &__month-text--selected:not([aria-disabled="true"]):hover,
    &__month-text--in-selecting-range:not([aria-disabled="true"]):hover,
    &__month-text--in-range:not([aria-disabled="true"]):hover,
    &__quarter-text--selected:not([aria-disabled="true"]):hover,
    &__quarter-text--in-selecting-range:not([aria-disabled="true"]):hover,
    &__quarter-text--in-range:not([aria-disabled="true"]):hover,
    &__year-text--selected:not([aria-disabled="true"]):hover,
    &__year-text--in-selecting-range:not([aria-disabled="true"]):hover,
    &__year-text--in-range:not([aria-disabled="true"]):hover {
      background-color: #5757b7;
      color: #fff;
    }

    &__year-read-view--down-arrow,
    &__month-read-view--down-arrow,
    &__month-year-read-view--down-arrow,
    &__navigation-icon::before {
      border-width: 2px 2px 0 0;
    }
  }

  label {
    transform: translateY(-60%);
    position: absolute;
    left: 18px;
    transition: all 0.2s ease;
    color: #757575;
    font-size: 0.75rem;
    top: 0;
    padding: 0 10px;
    background: #fff;
    z-index: 1;
  }
}

//CKEditor

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 100%;
  max-width: 100%;
}

.editor-container_include-block-toolbar {
  margin-left: 42px;
}

.ck-powered-by{
  display: none;
}




