.quantityCounter{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 4px;
  background: white;
  border-radius: 15px;

  &.disabled{
    cursor: pointer;
    opacity: .5;
    pointer-events: none;
  }

  .quantityCounterButton{
    button{
      background: unset;
      outline: none;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 100%;
      cursor: pointer;
      svg{
        width: 12px;
        height: 12px;
        fill: #fe2942;
        transition: all .4s ease;
      }
    }
    &.disabled{
      button{
        cursor: not-allowed;
        svg{
          fill: #b9b9b9;
        }
      }
    }
  }
  .quantityValue{
    width: 45px;
    height: 24px;
    input{
      width: 100%;
      height: 100%;
      text-align: center;
      border: 0;
      background: transparent;
      outline: none;
    }
  }
}