.productItems {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 74vh;
  height: 90%;
  min-width: 1300px;

  @media screen and (max-width: 1024px) {
    min-width: 100%;
    max-height: unset;
    height: 100%;
  }

  .productItem {
    display: grid;
    grid-template-columns: repeat(28, 1fr); // три колонки
    grid-template-rows: auto; // высота строки будет автоматической
    width: 100%;
    padding: 10px 15px;
    align-items: center;
    font-size: 0.815rem;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease;

    &:hover {
      background-color: #f8f8f8;
      @media screen and (max-width: 1024px) {
        background-color: #fff;
      }
    }
  }

  .productMore {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      background: linear-gradient(90deg, #6c6cc7, #5757b7);
      padding: 10px 20px;
      border-radius: 80px;
      cursor: pointer;
      width: 15%;
      transition: all 0.4s ease;
      opacity: 1;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        width: 50%;
      }
    }
  }

  .productItemColumn {
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    position: relative;
    .productItemColumnName {
      display: none;
    }
  }

  .checkbox {
    position: absolute;
    left: 15px;
    padding: 0;
  }

  .name{
    grid-column: span 4;
    grid-row: span 1;
  }

  .markup{
    grid-column: span 3;
    grid-row: span 1;
  }

  .period{
    grid-column: span 4;
    grid-row: span 1;
  }

  .priority{
    grid-column: span 2;
    grid-row: span 1;
  }

  .condition{
    grid-column: span 6;
    grid-row: span 1;
  }

  .segmentation{
    grid-column: span 4;
    grid-row: span 1;
  }

  .active {
    grid-column: span 2;
    grid-row: span 1;
    display: flex;
    justify-content: center;
  }

  .productItemTitle {
    display: none;
  }

  .edit{
    position: absolute;
    right: 15px;
  }

  @media screen and (max-width: 1024px) {
    .productItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .productItemColumn {
        width: 95% !important;
        display: flex;
        justify-content: space-between !important;
        gap: 5px;
        
        .productItemColumnName {
          display: block;
          width: 45%;
          text-align: start;
          padding: 7px 10px;
        }
        .productItemColumnValue{
          width: 50%;
          text-align: start;
          display: flex;
          align-items: center;
        }
      }
    }


    &.checkboxList {
      .productItem {
        padding: 35px 0 20px;
        .productItemColumn{
          padding: 0;
        }
        &.markups {
          .edit{
            position: absolute;
            top: 7px;
            left: 40px;
          }
        }
      }
      .checkbox {
        top: 10px;
        left: 10px;
      }
    }
  }
}
