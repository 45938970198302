.marketplacesItem{
  width: calc(100%/5 - 12px);
  position: relative;

  @media screen and (max-width: 1410px){
    width: 19%;
  }

  @media screen and (max-width: 1340px){
    width: calc(100%/4 - 12px);
  }

  @media screen and (max-width: 1160px){
    width: calc(100%/3 - 12px);
  }

  @media screen and (max-width: 768px){
    width: calc(100%/2 - 12px);
    min-height: 200px;
  }

  @media screen and (max-width: 480px){
    width: 100%;
  }

  padding: 20px 15px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  transition: all .4s ease;
  font-size: 0.875rem;
  text-align: center;
  min-height: 340px;

  .marketplacesItemStatus{
    &.plans{
      color: #0184db;
    }
    &.soon{
      color: #01c716;
    }
  }

  .marketplacesItemImg{
    height: 35%;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .marketplacesItemTools{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    svg{
      width: 27px;
      height: 27px;
      fill: #7b7b7b;
      cursor: pointer;
      transition: all .4s ease;

      &:hover{
        fill: #fe2942;
      }
    }
  }

  .marketplacesItemName{
    font-size: 1.3rem;
    color: #000;
    cursor: pointer;
  }

  .marketplacesItemConnect{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .marketplacesItemSwitch{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 15px;
    font-size: 0.75rem;

    &.off{
      color: #ccc;
    }

    &.on{
      color: #01c716;
    }
  }

  .marketplacesItemFile{
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    text-align: start;
    color: #b7b7b7;
    word-break: break-word;
    align-items: center;
    gap: 5px;
    margin-top: 20px;

    a{
      text-decoration: none;
      color: #0184db;
    }
  }
}