.catalogPage{
  width: 100%;
  height: 100%;
  .catalogMain{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      height: auto;
      max-height: 100%;
      flex-wrap: nowrap;
    }
    .catalogProducts{
      width: 79%;
      overflow-x: auto;
      padding-right: 15px;
      height: 100%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        padding-right: 5px;
      }
    }
  }
}