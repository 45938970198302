//ПЕРЕПИСАТЬ СТИЛИ
.modalCategoryList{
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 9px 10px 10px;
  position: relative;
  transition: all .4s ease;
  height: 82%;
  width: 100%;

  &.closed{
    padding: 7px 10px 10px;
    max-height: 44px;
  }

  &.open{
    padding: 7px 10px 10px;
    max-height: 9999px;
  }

  .modalCategoryListContainer{
    overflow: hidden;
  }

  .modalCategoryListTitle {
    transform: translateY(-60%);
    position: absolute;
    left: 18px;
    transition: all 0.2s ease;
    color: #757575;
    font-size: 0.75rem;
    top: 0;
    padding: 0 10px;
    background: #fff;
  }

  .modalCategoryCurrent{
    padding-left: 15px;
    padding-right: 5px;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .modalCategoryCurrentEdit{
      cursor: pointer;
      svg{
        path{ fill: #7b7b7b; transition: all .4s ease; }
        &:hover{
          path{ fill: #fe2942; transition: all .4s ease; }
        }
      }
    }
  }

  .modalCategoryListContent {
    overflow-y: auto;
    padding: 15px;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .4s ease;
  }
}