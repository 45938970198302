.button{
  width: max-content;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: 0;

  span {
    color: #fff;
    background: linear-gradient(90deg, #6c6cc7, #5757b7);
    padding: 10px 20px;
    border-radius: 80px;
    cursor: pointer;
    width: 100%;
    transition: all 0.4s ease;
    opacity: 1;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      width: 50%;
    }

    &:hover{
      opacity: .8;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}