.catalogCategoryItem {
  position: relative;
  font-size: 0.815rem;
  text-align: start;
}

.modalCategoryListContent {
  padding: 15px;
  transition: all .4s ease;
  height: 500px;
  visibility: visible;
  opacity: 1;
}

.catalogCategoryItemTitle {
  padding: 10px 0;
  position: relative;
  transition: all .4s ease;

  &:hover {
    color: #fe2942;
    cursor: pointer;
  }

  .catalogTitleCircle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 100%;
    left: -20px;
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 1;

    img {
      width: 6px;
      height: 6px;
      opacity: .6;
    }

    &.active {
      &::after {
        content: "";
        width: 6px;
        height: 6px;
        background-color: #767676;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translate(0%, -50%);
      }
    }
  }
}

.catalogCategoryItemLevel0 {
  padding-left: 15px;
  position: relative;

  .catalogCategoryItemLevel1 {
    display: none;
  }

  &.active {
    .catalogCategoryItemLevel1 {
      display: block;
    }
  }
}

@for $i from 1 through 10 {
  .catalogCategoryItemLevel-#{$i} {
    position: relative;

    .catalogCategoryItemLevel-#{$i + 1} {
      display: none;
    }

    &.active {
      .catalogCategoryItemLevel-#{$i + 1} {
        display: block;
      }
    }
  }
}
