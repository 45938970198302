.categoryListMobile {
  display: none;
  padding: 10px 20px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
  }

  div{
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      fill: #fe2942;
      width: 14px;
      height: 14px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
  }
}