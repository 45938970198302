.selectButton{
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  font-size: 90%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all .4s ease;
  &:hover{
    background-color: #e0e0e0;
  }
  span{
    margin-top: 1px;
  }
  .selectButtonSvg{
    width: 18px;
    height: 18px;
    div{
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
}