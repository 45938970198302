.catalogCategory {
  border: 2px solid #f1f1f1;
  width: 20%;
  padding: 8px 10px;
  font-size: 1.125rem;
  border-radius: 15px;
  height: 100%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .catalogTools {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 6px;

    .catalogToolsTight {
      display: flex;
      gap: 10px;

    }

    .i_tools-item {
      cursor: pointer;

      div {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
  }

  .catalogCategoryItems {
    display: flex;
    flex-direction: column;
    height: 92%;
    overflow-y: auto;
    padding: 0 0 15px 15px;
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
      background-color: rgba(118, 118, 118, 0.3);
    }

    &:hover{
      &::-webkit-scrollbar-thumb {
        display: block;
        background-color: #767676FF;
        transition: all .4s ease;
      }
    }

  }
}