.i_billing{
  .i_billing-tariff{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;
    width: 100%;

    .i_billing-tariff-title{
      color: #000;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .i_billing-tariff-items{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .i_billing-tariff-item{
        display: flex;
        gap: 5px;
        padding: 10px 0;
        width: 49%;
        @media screen and (max-width: 768px){
          width: 100%;
        }
        .i_billing-tariff-item-value{
          font-weight: 500;
        }
      }
    }
  }
}