/* CSS Normalize */

/* Document
   ========================================================================== */
*{
    box-sizing: border-box;
}

html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
}

body {
    margin: 0;
}

/* Sections
   ========================================================================== */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
}

pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
}

/* Lists
   ========================================================================== */

a {
    background-color: transparent;
}

/* Text-level semantics
   ========================================================================== */

b,
strong {
    font-weight: bolder;
}

/* Replaced content
   ========================================================================== */

img {
    border-style: none;
}

/* Forms
   ========================================================================== */

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

/* Remove the inner padding in Firefox */
button,
input {
    overflow: visible;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #fff inset !important;
}

/* Remove the inheritance of text transform in Edge and Firefox */
button,
select {
    text-transform: none;
}

/* Correct the inability to style clickable types in iOS and Safari */
button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

/* Remove the inner border and padding in Firefox */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/* Reset to invisible */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/* Hidden by default */
[hidden] {
    display: none;
}
