.itemCircle {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: #cdcdcd;

  &.active {
    background-color: #01c716;
  }
}