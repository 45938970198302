.loader{
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 10;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  top: 0;

  svg{
    width: 100px;
    height: 100px;
  }
}