.notice{
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 1px solid #ccc;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  margin-right: 15px;

  &:hover{
   svg{
    fill: #fe2942;
   }
  }

  @media screen and (max-width: 1024px){
    margin-right: 0;
  }

  svg{
    width: 25px;
    height: 25px;
    transition: all .4s ease;
  }

  .noticeCount{
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    background-color: #fe2942;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 0.625rem;
  }
}