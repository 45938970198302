.currency{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(50% - 15px);
  padding: 20px 15px;
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  height: 100%;
  min-height: 270px;
  max-height: 270px;
  @media screen and (max-width: 768px){
    width: 100%;
  }
  .currencyTitle{
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .currencyDefault{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    .currencyInfo{
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .currencyEdit{
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:hover{
      svg{
        fill: #fe2942;
      }
    }
    svg{
      width: 100%;
      height: 100%;
      transition: all .4s ease;
    }
  }

  .currencyList{
    .currencyListTitle{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  .currencyListItems{
    height: 110px;
    &>div{
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-top: 0;
      padding-bottom: 0;
      .currencyListItem{

      }
    }
  }
}